import { Component } from "react";

interface Props {
  img: string;
  title: string;
  description: string;
  firstIcon: string;
  firstWriteup: string;
  firstContent: string;
  secondIcon: string;
  secondWriteup: string;
  secondContent: string;
}

export default class LeftContentCard extends Component<Props> {
  render() {
    return (
      <>
        <div className="py-4 overflow-hidden sm:mt-8 sm:py-8 lg:py-4">
          <div className="relative mx-auto">
            <div className="relative mt-8 sm:mt-4 lg:mt-8">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-24 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-white tracking-tight sm:text-3xl">
                    {this.props.title}
                  </h3>
                  <p className="mt-3 text-sm text-justify text-white leading-7 sm:text-base">
                    {this.props.description}
                  </p>

                  <dl className="mt-10 space-y-10">
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 ">
                          <img src={this.props.firstIcon} alt="" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-white ">
                          {this.props.firstWriteup}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-sm text-white text-justify leading-7">
                        {this.props.firstContent}
                      </dd>
                    </div>

                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                          <img src={this.props.secondIcon} alt="" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-white">
                          {this.props.secondWriteup}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-sm text-white leading-7">
                        {this.props.secondContent}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mt-4 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <img src={this.props.img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
