import { Component } from "react";
interface Props {
  title: string;
  desc: string;
  img: string;
  icon: string;
  outputs: string[];
}

export default class Solution extends Component<Props> {
  render() {
    return (
      <>
        <div className="w-full flex flex-col mt-20">
          <p className="text-3xl font-extrabold mb-4">{this.props.title}</p>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col mr-4">
              <span className="mt-3 text-sm text-justify text-white leading-7 sm:text-base">
                {this.props.desc}
              </span>
              <div className="flex flex-row mt-8">
                <div className="flex w-9 h-9 bg-sky-500 rounded-md justify-center items-center mr-4">
                  <img src={this.props.icon} alt="" />
                </div>
                <div className="flex flex-col">
                  <span>General Output</span>
                  <ul className="list-disc ml-4 mt-4">
                    <div className="grid grid-rows-5 grid-flow-col gap-y-2 gap-x-6 text-xs sm:text-sm">
                      {this.props.outputs.map((output, index) => {
                        return <li key={index}>{output}</li>;
                      })}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <img src={this.props.img} alt="" className="mt-6 lg:m-0 lg:ml-4 " />
          </div>
        </div>
      </>
    );
  }
}
