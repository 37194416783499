import React, { Component } from "react";
import Analytics from "../assets/services_analytics.png";
import Camera from "../assets/services_camera.png";
import Cloud from "../assets/services_cloud.png";
import MappingAndAnalytics from "../assets/services_construction_view.png";
import DroneMapping from "../assets/services_drone.png";
import EstateAerialView from "../assets/services_estate.png";
import Housing from "../assets/services_house.png";
import InfrastructureInspection from "../assets/services_inspect.png";
import VisualInspection from "../assets/services_inspection.png";
import IOT from "../assets/services_iot.png";
import IOTSolutions from "../assets/services_iot_solution.png";
import Magnifier from "../assets/services_magnifier.png";
import Mapping from "../assets/services_map.png";
import Model from "../assets/services_model.png";
import TempScanner from "../assets/services_scanner.png";
import WirelessSolution from "../assets/services_wireless.png";
import Footer from "../components/Footer";
import Header, { Pages } from "../components/Header";
import LeftContentCard from "../components/marketing/home/LeftContentCard";
import RightContentCard from "../components/marketing/home/RightContentCard";

const services = [
  {
    icon: DroneMapping,
    title: "Drone Mapping & Data Processing",
  },
  {
    icon: EstateAerialView,
    title: "Real Estate Aerial View",
  },
  {
    icon: InfrastructureInspection,
    title: "Infrastructure Inspection",
  },
  {
    icon: IOTSolutions,
    title: "Customised IOT Solutions ",
  },
];

export default class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="relative bg-gray-900 overflow-hidden">
          <Header page={Pages.SERVICES} />
          <div className="mx-auto max-w-lg sm:max-w-7xl px-8 text-white mb-36">
            <div className="flex flex-col items-center justify-center my-8 font-semibold">
              <span className="text-3xl sm:text-4xl tracking-tight font-extrabold mb-4">
                Our Services
              </span>
              <span className="mt-3 text-center text-base text-gray-300 sm:mt-5 sm:text-lg md:max-w-3xl">
                We specialize in drone operation, customizing software and
                hardware integration which allow us to offer a wide range of
                solutions catered to industry needs
              </span>
            </div>
            <div className="grid grid-cols-1 gap-y-4 gap-x-4 lg:grid-cols-4 sm:grid-cols-2 lg:gay-y-0">
              {services.map((service) => {
                return (
                  <div className="bg-gray-800 rounded-3xl flex flex-row justify-center items-center py-4 px-2 font-extrabold my-2 lg:mx-0">
                    <img
                      src={service.icon}
                      className="w-16 h-16 object-cover mx-2"
                      alt=""
                    />
                    <span>{service.title}</span>
                  </div>
                );
              })}
            </div>
            <LeftContentCard
              img={MappingAndAnalytics}
              firstIcon={Mapping}
              secondIcon={Analytics}
              title="Drone Mapping & Data Processing"
              description="Space is the limit! We pilot drones above survey sites to capture image data points efficiently. This allows us to further execute machine learning algorithms for object detection and inventory counting."
              firstWriteup="2D & 3D Mapping"
              firstContent="We produce high-resolution aerial maps containing precise GPS Coordinate for distance and surface area calculation of the objects. Additionally, 3D data is able to provide detailed calculations in terms of height and volumes of an infrastructure. "
              secondWriteup="Data Analytics"
              secondContent="A wide range of data analytics can be done via our customized machine learning algorithms to analyse agriculture data like tree counting and provide more insights for users to make data-driven decision-making."
            />
            <RightContentCard
              img={VisualInspection}
              firstIcon={Magnifier}
              secondIcon={TempScanner}
              title="Infrastructure Inspections"
              description="Get an up-close view of your asset safely and quickly via the high-resolution and zoom capability of drone cameras. The captured drone data can be further analysed to identify problem areas via computer vision techniques."
              firstWriteup="Visual Inspection"
              firstContent="Gain a bird’s eye view of the job site quickly and utilize high-zooming camera to get a close inspection of any problem areas"
              secondWriteup="Thermal Inspection"
              secondContent="View the heat signatures of infrastructure like solar panels or electrical grids via thermal imaging for any malfunction inspections"
            />
            <LeftContentCard
              img={Housing}
              firstIcon={Camera}
              secondIcon={Model}
              title="Real Estate Aerial View"
              description="Showcase your gorgeous home exteriors via dramatic drone footage by using 4K high-resolution drone cameras. The aerial images can be used to market your property on social media and support your interior shots on your digital listing page."
              firstWriteup="Aerial Photography & Videography"
              firstContent="Showcase your property area with cinematic drone shots by seamlessly fly-overs the housing area. The drone footage can be a robust marketing medium for the sale of sites, and listings "
              secondWriteup="3D Modeling of Site "
              secondContent="3D modeling enables more dynamic visual tours and digitally highlights more property features like landscaping, pools, walking paths, and back yards. "
            />
            <RightContentCard
              img={IOT}
              firstIcon={Cloud}
              secondIcon={WirelessSolution}
              title="Customized IOT Solutions"
              description="Having  a specific niche issue that you are currently facing in your industry? Schedule a call with our team for us to understand your pain points better and propose cost-effective solutions"
              firstWriteup="Seamless Cloud System"
              firstContent="Having all your data uploaded to the clouds allows you to make key actions based on the data-driven visualisation and save costs in the long run. "
              secondWriteup="Wireless Network Solution"
              secondContent="Customize communication between devices via low power consumption technology like BLE(Bluetooth Low Energy) or LoRa technology to transmit data. It is able to  works normally in offline mode and re-sync to the cloud when internet connectivity is available."
            />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
