import React, { Component } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import ReportDetail from "../pages/ReportDetail";
import ReportPreview from "../pages/ReportPreview";
import Services from "../pages/Services";
import Solutions from "../pages/Solutions";

declare global {
  interface Window {
    google: any;
  }
}

class Navigator extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("UA-211579979-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    let mapsScript = document.getElementById("googleMaps");

    if (!mapsScript) {
      const mapAPIKey = process.env.REACT_APP_MAP_API;
      const mapScript = document.createElement("script");
      mapScript.id = "googleMaps";
      mapScript.type = "text/javascript";
      mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${mapAPIKey}&libraries=places,drawing,geometry`;

      const head = document.getElementsByTagName("head")[0];
      if (head) {
        document.head.appendChild(mapScript);
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/reportDetail" component={ReportDetail} />
          <Route exact path="/reportPreview" component={ReportPreview} />
          {/* <Route exact path="/login" component={Login} /> */}
          {/* <Route exact path="/dashboard" component={Sites} />
          <Route exact path="/dashboard/siteDetail" component={SiteDetail} /> */}
          {/* <Route exact path="/dashboard/reports" component={Reports} /> */}
          {/* <Route
            exact
            path="/dashboard/reportDetail"
            component={ReportDetail}
          />
          <Route
            exact
            path="/dashboard/appointments"
            component={Appointments}
          />
          <Route
            exact
            path="/dashboard/appointmentDetail"
            component={AppointmentDetail}
          /> */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Navigator;
