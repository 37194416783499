import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeroBg from "../../../assets/hero_bg.png";

interface Props {
  landing: boolean;
  title: string;
  titleCyan: string;
  description: string;
  image?: string;
}

export default class Hero extends Component<Props> {
  render() {
    return (
      <main>
        <div className="pt-10 pb-60 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  {this.props.landing && (
                    <div className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                      <Link
                        className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-cyan-500 rounded-full"
                        to="/solutions"
                      >
                        Our Solutions
                      </Link>
                      <Link to="/services" className="ml-4 text-sm">
                        Visit our services
                      </Link>
                      <ChevronRightIcon
                        className="ml-2 w-5 h-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">{this.props.title}</span>
                    <span className="block text-sky-500">
                      {this.props.titleCyan}
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    {this.props.description}
                  </p>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={HeroBg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
