import {
  EnquiriesStateAttribute,
  EnquriesStateError,
} from "../../../pages/ContactUs";

export const handleEnquiriesCondition = (
  enquiriesState: EnquiriesStateAttribute,
  enquiriesStateError: EnquriesStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (enquiriesState.name.replace(/\s/g, "").length <= 0) {
          enquiriesStateError["nameError"] = "Please enter your name";
        } else {
          enquiriesStateError["nameError"] = "";
        }
        break;
      case "mobileNo":
        const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
        if (!phoneFilter.test(enquiriesState.mobileNo)) {
          enquiriesStateError["mobileNoError"] =
            "Please enter your contact number in the correct format of +60123456789 (Example)";
        } else {
          enquiriesStateError["mobileNoError"] = "";
        }
        break;
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */

        if (!filter.test(enquiriesState.email.replace(/\s/g, ""))) {
          enquiriesStateError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          enquiriesStateError["emailError"] = "";
        }
        break;
      case "message":
        if (enquiriesState.message.replace(/\s/g, "").length < 6) {
          enquiriesStateError["messageError"] =
            "Please enter your enquiries message";
        } else if (enquiriesState.message.replace(/\s/g, "").length > 1000) {
          enquiriesStateError["messageError"] =
            "You had exceeded the character limit of 1000 characters";
        } else {
          enquiriesStateError["messageError"] = "";
        }
        break;
    }
    return null;
  });
};
