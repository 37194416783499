import React, { Component } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/marketing/home/Hero";

export default class NotFound extends Component {
  render() {
    return (
      <div className="relative bg-gray-900 min-h-screen overflow-hidden">
        <Header />
        <Hero
          title="404"
          titleCyan="Page Not Found"
          description="We trying finding this page everywhere but it cannot be found. Back to Home Page ?"
          landing={true}
        />
        <Footer />
      </div>
    );
  }
}
