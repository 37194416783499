import React, { Component } from "react";
import AiDriven from "../assets/solutions_ai.png";
import Analyze from "../assets/solutions_analyze.png";
import DataAccessible from "../assets/solutions_cloud.png";
import CostEffective from "../assets/solutions_cost_effective.png";
import MeasurementAndInspection from "../assets/solutions_inspection.png";
import TimeEfficiency from "../assets/solutions_time.png";
import TopographySurvey from "../assets/solutions_topography.png";
import VisualVsThermal from "../assets/solutions_visual_thermal.png";
import Footer from "../components/Footer";
import Header, { Pages } from "../components/Header";
import Solution from "../components/marketing/home/Solution";

const solutions = [
  {
    img: AiDriven,
    title: "AI-Driven Solutions",
    desc: "Drone data being processed by artificial intelligence and computer vision algorithms",
  },
  {
    img: CostEffective,
    title: "Cost-Effective",
    desc: "We offer value-added services that significantly saves cost again traditional methods",
  },
  {
    img: TimeEfficiency,
    title: "Time-Efficient",
    desc: "Large area coverage via drone footage provides fast and reliable data for decision making",
  },
  {
    img: DataAccessible,
    title: "Data Accessibility",
    desc: "Airlytic cloud platform enables data to be securely accessed and visualized anytime.",
  },
];

const examples = [
  {
    title: "Data-Driven Precision Agriculture",
    desc: "Drone deployment in agriculture sectors offer farmer major cost-saving and enhance their daily operational efficiency. We offer a wide range of geospatial analyses to consistently survey  and monitor crops yield cycles in order to maximise harvesting productivity.",
    icon: Analyze,
    outputs: [
      "99% Tree counting accuracy",
      "High Resolutions Orthophoto",
      "Elevation & Contour Map",
      "Digital Terrain Model (DTM)",
      "Digital Surface Model (DSM)",
      "Plant health Analysis",
      "Infrastructure and roads digitisation",
      "Landuse Analysis",
      "Slope Map",
      "Vacant Point Analysis",
    ],
    img: VisualVsThermal,
  },
  {
    title: "Aerial Measurement and Inspection",
    desc: "Essential parts of a building can be inspected with high resolution visual or thermal camera of the drone at a safe distance. Thus, drone utilisation help to minimize costs associated with accessing hard-to-reach areas like rooftops or high-rise building platforms and reduce the risks of accidents happen.",
    icon: Analyze,
    outputs: [
      "High Precision Orthophoto",
      "Distance Measurement",
      "Surface Area Calculation ",
      "Roof Angle Measurement",
      "Cinematic Shot of Infrastructure",
      "Visual Inspection on crack/fautly parts",
      "Thermal inspection on malfunction parts",
      "3D modeling",
      "Volumetric Analysis",
    ],
    img: MeasurementAndInspection,
  },
  {
    title: "Topography Survey in Construction",
    desc: "Capturing topographic data with a drone produces a significantly faster and more reliable result within a larger coverage area. The drone surveying method is proven to be cost-effective and time-efficient compared to land-based surveying methods which require more manpower and resources.",
    icon: Analyze,
    outputs: [
      "High Precision Orthophoto",
      "3D Modeling",
      "Distance measurements",
      "Surface Area Calculation",
      "Digital Terrain Model (DTM) ",
      "Digital Surface Model (DSM)",
      "Contour Map",
      "Volumetric Analysis",
      "Aerial Surveillance ",
      "Construction Progress Monitoring",
    ],
    img: TopographySurvey,
  },
];

export default class Solutions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="relative bg-gray-900 overflow-hidden">
          <Header page={Pages.SOLUTIONS} />
          <div className="mx-auto max-w-lg sm:max-w-7xl px-8 text-white mb-36">
            <div className="flex flex-col items-center justify-center my-8 font-semibold">
              <span className="text-3xl sm:text-4xl tracking-tight font-extrabold mb-4">
                Our Solutions
              </span>
              <span className="mt-3 text-center text-base text-gray-300 sm:mt-5 sm:text-lg md:max-w-3xl">
                Airlytic provide drone mapping and aerial analytics solutions to
                streamline industrial workflow via the main core value
                proposition below.
              </span>
              <div className="mt-8 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-2 lg:grid-cols-4 lg:gay-y-0">
                {solutions.map((solution) => {
                  return (
                    <div className="bg-gray-800 rounded-m my-2 lg:mx-0">
                      <div className="flex flex-col justify-center items-center m-8">
                        <div className="w-24 h-24 flex rounded-full bg-sky-500 justify-center items-center">
                          <img
                            src={solution.img}
                            className="w-16 h-16"
                            alt=""
                          />
                        </div>
                        <span className="text-lg text-center my-3 font-extrabold">
                          {solution.title}
                        </span>
                        <span className="text-sm text-center leading-6">
                          {solution.desc}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {examples.map((example) => {
                return (
                  <Solution
                    title={example.title}
                    desc={example.desc}
                    icon={example.icon}
                    outputs={example.outputs}
                    img={example.img}
                  />
                );
              })}
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
