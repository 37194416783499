import axios from "axios";
import { ReadQuery } from "blixify-ui-web/lib/components/data/readQuery";
import { WriteQuery } from "blixify-ui-web/lib/components/data/writeQuery";

export const sitesId = "0f922ca0-cd65-42e6-a2ad-04030f895fc3";
export const inspectionId = "ec35ceef-1f0a-4cf2-a59e-b9af0e98d70f";
export const defectId = "af6afa82-1ecd-4136-adc4-699479e08835";

export const isProd =
  process.env.REACT_APP_SERVER_ENV === "production" ? true : false;

export const tileUrl =
  process.env.REACT_APP_SERVER_ENV === "production"
    ? "https://tile.airlytic.co"
    : "https://tile.airlytic.co";

export const assetAPI = !isProd
  ? "https://tile.airlytic.co/storage/dev"
  : "https://tile.airlytic.co/storage/dev";
export const queryAPI = `https://space${
  !isProd ? "-dev" : ""
}.blixify.co/api/spaceD`;

export const RQ = (collection: string) => {
  return new ReadQuery({
    dbModule: axios,
    dbType: "mongoServer",
    dbCollection: collection,
    dbEndpoint: queryAPI,
  });
};

export const WQ = (collection: string) => {
  return new WriteQuery({
    dbModule: axios,
    dbType: "mongoServer",
    dbCollection: collection,
    dbEndpoint: queryAPI,
  });
};

export const sitesRQ = RQ(sitesId);
export const inspectionsRQ = RQ(inspectionId);
export const defectsRQ = RQ(defectId);
export const defectsWQ = WQ(defectId);
