import { Defect } from "../../../model/Defect";

export const handleDefectsGrouping = (defects: Defect[]) => {
  // Step 1: Group all points according to their INV
  const groupedData = defects.reduce((acc: any, item) => {
    const location = item.location;
    const zone = location.charAt(0);
    const invGroup = location.match(/INV(\d+)/);
    if (invGroup) {
      const inv = invGroup[1];

      if (!acc[zone]) {
        acc[zone] = {};
      }

      if (!acc[zone][inv]) {
        acc[zone][inv] = [];
      }

      acc[zone][inv].push(item);
    }

    return acc; // Returning the updated accumulator object.
  }, {});
  // Step 2: Calculate xSquares and ySquares for each group
  const distanceOffset = 15; // Value of distance in (m), have to convert to km if needed
  const squaresDrawn: { [key: string]: any } = {};
  for (const zone in groupedData) {
    for (const inv in groupedData[zone]) {
      const defects: Defect[] = groupedData[zone][inv];
      const result = handleCalculateSquareCount(defects, distanceOffset);
      const coordinates = handleConvertDistanceToCoordinates(
        result.points.minX,
        result.points.minY,
        distanceOffset / 1000 //convert distance to km
      );
      const latDiff = Math.abs(coordinates.lat - result.points.minX);
      const lngDiff = Math.abs(coordinates.lon - result.points.minY);
      const squares = handleDrawSquares(
        result.points.minX,
        result.points.minY,
        latDiff,
        lngDiff,
        result.size.squaresX,
        result.size.squaresY
      );
      for (const square of squares) {
        if (!squaresDrawn[zone]) {
          squaresDrawn[zone] = {};
        }
        if (!squaresDrawn[zone][inv]) {
          squaresDrawn[zone][inv] = [];
        }
        const defectList: Defect[] = [];
        defects.forEach((defect) => {
          if (
            defect.lat >= square.x &&
            defect.lat <= square.maxX &&
            defect.lng >= square.y &&
            defect.lng <= square.maxY
          ) {
            defectList.push(defect);
          }
        });
        if (defectList.length > 0)
          squaresDrawn[zone][inv].push({ square, defectList });
      }
    }
  }
  return squaresDrawn;
};

const handleCalculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert degrees to radians
  const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

  // Calculate the differences between the latitudes and longitudes
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  // Calculate the Haversine formula
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  //the distance returned will be in (km)
  return distance;
};

const handleConvertDistanceToCoordinates = (
  lat: number,
  lon: number,
  distance: number
) => {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert degrees to radians
  const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

  // Convert radians to degrees
  const toDegrees = (radians: number) => (radians * 180) / Math.PI;

  // Convert distance to radians
  const toDistanceRadians = (distance: number) => distance / earthRadius;

  const latRad = toRadians(lat);
  const lonRad = toRadians(lon);
  const distanceRad = toDistanceRadians(distance);

  const lat2Rad = Math.asin(
    Math.sin(latRad) * Math.cos(distanceRad) +
      Math.cos(latRad) * Math.sin(distanceRad)
  );

  const lon2Rad =
    lonRad +
    Math.atan2(
      Math.sin(distanceRad) * Math.cos(latRad),
      Math.cos(distanceRad) - Math.sin(latRad) * Math.sin(lat2Rad)
    );

  const lat2 = toDegrees(lat2Rad);
  const lon2 = toDegrees(lon2Rad);

  return { lat: lat2, lon: lon2 };
};

const handleCalculateSquareCount = (defects: Defect[], distance: number) => {
  // Find the minimum and maximum x and y coordinates
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  for (const defect of defects) {
    minX = Math.min(minX, defect.lat);
    maxX = Math.max(maxX, defect.lat);
    minY = Math.min(minY, defect.lng);
    maxY = Math.max(maxY, defect.lng);
  }

  // Calculate the range in x and y directions
  // Calculation the distance (Lat - Different, Lng - Same(0,0))
  const rangeX = handleCalculateDistance(maxX, 0, minX, 0);
  // Calculation the distance (Lat - 0, Lng - Different))
  const rangeY = handleCalculateDistance(0, maxY, 0, minY);

  // Calculate the number of squares in each direction
  const squaresX = Math.ceil((rangeX * 1000) / distance);
  const squaresY = Math.ceil((rangeY * 1000) / distance);

  return {
    size: {
      squaresX,
      squaresY,
    },
    points: {
      minX,
      maxX,
      minY,
      maxY,
    },
  };
};

const handleDrawSquares = (
  minX: number,
  minY: number,
  latDiff: number,
  lngDiff: number,
  noX: number,
  noY: number
) => {
  const squares: any = [];
  for (let i = 0; i <= noX; i++) {
    for (let j = 0; j <= noY; j++) {
      const x = minX + i * latDiff;
      const y = minY + j * lngDiff;
      squares.push({
        x,
        y,
        maxX: x + latDiff,
        maxY: y + lngDiff,
      });
    }
  }
  return squares;
};
