import { Disclosure, Menu, Popover, Transition } from "@headlessui/react";
import { Header } from "blixify-ui-web/lib";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

const navigation = [
  { name: "Sites", href: "/dashboard" },
  { name: "Reports", href: "/dashboard/reports" },
  { name: "Appointments", href: "/dashboard/appointments" },
];

const userNavigation = [
  { name: "My Profile", href: "/dashboard/profile" },
  { name: "Sign out", href: "/" },
];

interface Props {
  page: string;
  history: any;
}

export default class CustomHeader extends Component<Props> {
  // componentDidMount = () => {
  //   if (localStorage.getItem("token") !== "hcjHHmQHCLefrMC9") {
  //     this.props.history.push("/login");
  //   }
  // };

  render() {
    return (
      <Header
        defaultNav="/"
        page={this.props.page}
        logo={Logo}
        navigation={navigation}
        userNavigation={userNavigation}
        user={{
          name: "Louis Ong",
          email: "louis@airlytic.co",
          image: Logo,
        }}
        lib={{
          Disclosure,
          Menu,
          Transition,
          Popover,
        }}
        linkType="react"
        custom={Link}
      />
    );
  }
}
