import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import {
  Button,
  Container,
  DetailList,
  EmptyState,
  Loading,
  Modal,
  Table,
} from "blixify-ui-web/lib";
import html2canvas from "html2canvas";
import L from "leaflet";
import moment from "moment";
import React, { Component } from "react";
import EmptyReport from "../assets/report_empty.jpg";
import Header from "../components/dashboard/Header";
import MapView from "../components/dashboard/MapView";
import { handleDefectsGrouping } from "../components/store/actions/defectActions";
import { Defect } from "../model/Defect";
import { Inspection } from "../model/Inspection";
import { Site } from "../model/Site";
import { sleep } from "../utils";
import {
  assetAPI,
  defectId,
  defectsRQ,
  defectsWQ,
  inspectionsRQ,
  sitesRQ,
  tileUrl,
} from "../utils/clientQuery";

interface Props {
  history: any;
}

interface ScreenShot {
  [key: string]: {
    rgb?: Blob;
    thermal?: Blob;
    plan?: Blob;
  };
}

export interface ScreenshotProps {
  type: string;
  defectList: Defect[];
  bounds: any;
}

export interface DefectSquare {
  defectList: Defect[];
  square: {
    maxX: number;
    maxY: number;
    x: number;
    y: number;
  };
}

export interface GroupedDefects {
  [key: string]: {
    [key: string]: DefectSquare[];
  };
}

interface State {
  inspection: Inspection;
  site: Site;
  defects: Defect[];
  isEmpty: boolean;
  loading: boolean;
  loadingModalText: string;
  reportLoading: boolean;
  groupedDefects: GroupedDefects;
  screenshotList: ScreenShot;
  mapWidth: number;
  mbtileExistance: string[];
  screenshotProps: ScreenshotProps;
  modalVisible: boolean;
}

export interface CustomTileLayerOptions extends L.TileLayerOptions {
  inspectionId: string;
  type: string;
  opacity?: number;
}

export default class ReportPreview extends Component<Props> {
  state: State = {
    inspection: {
      _id: "",
      siteId: "",
      objective: "",
      date: new Date(),
      time: "",
      person: "",
      sunRadiation: 0,
      temp: 0,
      wind: 0,
      weather: "",
      drone: "",
      rgbImage: "",
      thermalImage: "",
    },
    site: {
      _id: "",
      address: {
        lat: 0,
        lng: 0,
        name: "",
      },
      capacity: 0,
      mount: "",
      name: "",
      inverters: [],
      lossFactor: {
        cell: 0,
        double: 0,
        hot: 0,
        single: 0,
      },
      peakHourSunlight: 4,
      ppaRate: 0,
      pvConfiguration: {
        column: 10,
        row: 2,
      },
      pvNumberPerString: 30,
      pvSpec: [],
    },
    defects: [],
    isEmpty: true,
    loading: true,
    loadingModalText: "",
    reportLoading: false,
    groupedDefects: {},
    screenshotList: {},
    mapWidth: 0,
    mbtileExistance: [],
    screenshotProps: {
      type: "",
      bounds: null,
      defectList: [],
    },
    modalVisible: false,
  };

  componentDidMount = async () => {
    while (!window.google) {
      await sleep(1000);
    }
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    try {
      if (selectedId) {
        const inspectionResp = await inspectionsRQ.call({
          type: "get",
          id: selectedId ?? "",
        });
        if (inspectionResp?.data) {
          const siteResp = await sitesRQ.call({
            type: "get",
            id: inspectionResp.data.siteId ?? "",
          });
          const defectsResp = await defectsRQ.call({
            type: "list",
            stopLimit: true,
            query: [
              {
                type: "=",
                queryId: "inspectionId",
                value: inspectionResp.data._id,
              },
            ],
          });
          this.setState(
            {
              inspection: inspectionResp.data,
              site: siteResp?.data,
              defects: defectsResp?.data,
              isEmpty: false,
            },
            async () => {
              const groupedDefects = handleDefectsGrouping(this.state.defects);
              this.setState({
                groupedDefects,
              });
              const keys = ["rgb", "thermal", "plan"];
              for await (const key of keys) {
                try {
                  const res = await axios.get(
                    `${tileUrl}/download/dev?inspectionId=${this.state.inspection._id}&type=${key}`
                  );
                  if (res.data.success) this.state.mbtileExistance.push(key);
                } catch (err) {}
              }
              this.setState({ loading: false });
            }
          );
        } else throw "Error";
      } else throw "Error";
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  handleScreenshot = () => {
    const promise = new Promise<Blob>((resolve) => {
      let element = document.getElementById("map-preview");
      if (element) {
        html2canvas(element, {
          useCORS: true,
          allowTaint: true,
        }).then((canvas: any) => {
          canvas.toBlob((b: Blob) => {
            canvas = null;
            if (b) resolve(b);
          });
        });
      }
    });
    return promise;
  };

  handleScreenshotList = async (type: string, regenerate: boolean) => {
    try {
      this.setState({
        mapWidth: 300,
      });
      let groupedDefects = JSON.parse(
        JSON.stringify(this.state.groupedDefects)
      );
      let blob: Blob;
      if (!regenerate) {
        const filteredGroupedDefects: GroupedDefects = {};
        for (const zone in groupedDefects) {
          if (Object.prototype.hasOwnProperty.call(groupedDefects, zone)) {
            filteredGroupedDefects[zone] = {};

            for (const inv in groupedDefects[zone]) {
              if (
                Object.prototype.hasOwnProperty.call(groupedDefects[zone], inv)
              ) {
                const filteredDefectSquares = groupedDefects[zone][inv].filter(
                  (defectSquare: DefectSquare) => {
                    return !Object.prototype.hasOwnProperty.call(
                      defectSquare.defectList[0],
                      type
                    );
                  }
                );
                if (filteredDefectSquares.length > 0) {
                  filteredGroupedDefects[zone][inv] = filteredDefectSquares;
                }
              }
            }
          }
        }
        groupedDefects = filteredGroupedDefects;
      }
      let index = 1;
      const zones = Object.keys(groupedDefects);
      let length = 0;
      for (const zone in groupedDefects) {
        for (const key in groupedDefects[zone]) {
          length += groupedDefects[zone][key].length;
        }
      }
      for (const zone of zones) {
        const keys = Object.keys(groupedDefects[zone]);
        for (const key of keys) {
          for (const eachGroup of groupedDefects[zone][key]) {
            this.handleModalText(
              `Capturing ${index} / ${length} ${type} screenshots`
            );
            if (!this.state.screenshotList[eachGroup.defectList[0]._id]) {
              this.state.screenshotList[eachGroup.defectList[0]._id] = {};
            }
            const latLngBounds = L.latLngBounds([
              [eachGroup.square.maxX, eachGroup.square.maxY],
              [eachGroup.square.x, eachGroup.square.y],
            ]);

            const screenshotProps = {
              type,
              defectList: eachGroup.defectList,
              bounds: latLngBounds,
            };
            this.setState({
              screenshotProps,
            });
            await sleep(1000);
            blob = await this.handleScreenshot();

            if (blob) {
              switch (screenshotProps.type) {
                case "rgb":
                  this.state.screenshotList[
                    screenshotProps.defectList[0]._id
                  ].rgb = blob;
                  break;
                case "thermal":
                  this.state.screenshotList[
                    screenshotProps.defectList[0]._id
                  ].thermal = blob;
                  break;
                case "plan":
                  this.state.screenshotList[
                    screenshotProps.defectList[0]._id
                  ].plan = blob;
                  break;
              }
            }
            index++;
          }
        }
      }
    } catch (err) {}
  };

  handleModalText = (text: string) => {
    this.setState({
      loadingModalText: text,
    });
  };

  handleReportChecking = () => {
    const reportGenerated = this.state.defects.filter((defect: Defect) => {
      return (
        Object.prototype.hasOwnProperty.call(defect, "rgb") &&
        Object.prototype.hasOwnProperty.call(defect, "thermal") &&
        Object.prototype.hasOwnProperty.call(defect, "plan")
      );
    });
    if (reportGenerated.length > 0) {
      this.setState({
        modalVisible: true,
      });
    } else {
      this.handleGenerateReport(true);
    }
  };

  handleGenerateReport = async (regenerate: boolean) => {
    try {
      this.setState({
        reportLoading: true,
        modalVisible: false,
      });

      for (const type of this.state.mbtileExistance) {
        await this.handleScreenshotList(type, regenerate);
      }

      const keys = Object.keys(this.state.screenshotList);
      const batchSize = 20;
      // eslint-disable-next-line
      let cumulativeCount = 0;
      for (let i = 0; i < keys.length; i += batchSize) {
        const batchKeys = keys.slice(i, i + batchSize);
        // eslint-disable-next-line no-loop-func
        const uploadPromises = batchKeys.map(async (key) => {
          cumulativeCount++;
          this.handleModalText(
            `Uploading ${cumulativeCount} / ${keys.length} Screenshots`
          );
          const defect = this.state.defects.find((item) => item._id === key);

          if (defect) {
            const { screenshotList } = this.state;
            const { thermal, rgb, plan } = screenshotList[key];

            const [thermalUrl, rgbUrl, planUrl] = await Promise.all([
              this.handleUploadPhoto(thermal, "thermal", key),
              this.handleUploadPhoto(rgb, "rgb", key),
              this.handleUploadPhoto(plan, "plan", key),
            ]);

            defect.thermal = thermalUrl;
            defect.rgb = rgbUrl;
            defect.plan = planUrl;

            await defectsWQ.call("update", {
              id: defect._id,
              data: defect,
            });
          }
        });

        await Promise.all(uploadPromises);
      }
      this.setState({
        reportLoading: false,
      });
      this.props.history.push(
        `/reportDetail?data=${this.state.inspection._id}`
      );
    } catch (err) {
      this.setState({
        reportLoading: false,
      });
    }
  };

  handleUploadPhoto = async (
    blob: Blob | undefined,
    type: string,
    key: string
  ) => {
    if (blob !== undefined && blob instanceof Blob) {
      const photoForm = new FormData();
      const imageFile = new File([blob], `${type}.png`);
      photoForm.append("file", imageFile);
      photoForm.append("data[assetCollectionName]", defectId);
      photoForm.append("data[bucketName]", "airlytic-dev");
      photoForm.append("data[assetFileName]", `${type}.png`);
      photoForm.append("data[assetParentId]", key);
      const photoRes = await axios({
        method: "post",
        url: `${assetAPI}/upload`,
        data: photoForm,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!photoRes) throw new Error("Error");
      return photoRes.data.data;
    }
  };

  renderGroupedDefectsList = () => {
    const groupedDefects = JSON.parse(
      JSON.stringify(this.state.groupedDefects)
    );
    const defectsData: any = [];

    Object.keys(groupedDefects)
      .sort()
      .forEach((zone) => {
        Object.keys(groupedDefects[zone]).forEach((key) => {
          groupedDefects[zone][key].forEach((eachGroup: any) => {
            const fieldKeys = ["legend", "location", "priority", "lat", "lng"];
            const fieldData = fieldKeys.reduce((data: any, field) => {
              data[field] = (
                <ul>
                  {eachGroup.defectList.map((defect: any) => (
                    <li className="text-xs mt-1 ">{defect[field]}</li>
                  ))}
                </ul>
              );
              return data;
            }, {});
            defectsData.push(fieldData);
          });
        });
      });
    return defectsData;
  };

  renderReportLoading = () => {
    return (
      <div className="flex flex-col items-center">
        <Loading />
        <div className="mt-8 text-md leading-6 font-medium text-gray-900 sm:text-lg">
          {this.state.loadingModalText}
        </div>
      </div>
    );
  };

  renderContinuousReportGeneration = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="px-0">
          <h6 className="mt-5 text-gray-700">
            Detected unsaved report generation on going, do you want to
            continue?
          </h6>
          <span className="mt-3 text-gray-500 text-sm">
            (Yes - Continue, No - Regenerate Report)
          </span>
          <div className="flex flex-row mt-2 pt-16 justify-end">
            <Button
              text="No"
              size="small"
              type="light"
              onClick={() => {
                this.handleGenerateReport(true);
              }}
            />
            <Button
              className="ml-4"
              text="Yes"
              size="small"
              type="danger"
              onClick={() => {
                this.handleGenerateReport(false);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderInspection = () => {
    if (this.state.loading) {
      return (
        <div className="mt-24 flex flex-col items-center">
          <Loading />
          <div className="mt-8 text-md leading-6 font-medium text-gray-900 sm:text-lg">
            Please wait while we are generating preview
          </div>
        </div>
      );
    } else {
      if (this.state.isEmpty) {
        return (
          <EmptyState
            className="mt-12 mx-32 bg-white"
            icon={<img src={EmptyReport} className="w-48" alt="Empty state" />}
            text="The report is unavailable. Please make sure you are visiting the correct report link."
          />
        );
      } else {
        return (
          <Container className="bg-gray-50">
            <div className="w-full flex flex-row mt-10 pb-8">
              <div className="flex flex-col sm:items-center sm:flex-row">
                <div>
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                    {`${this.state.site.name} Inspection - ${moment(
                      this.state.inspection.date
                    ).format("DD/MM/YYYY")}`}
                  </h1>
                </div>
              </div>
              <div className="flex-1"></div>
              <div className="flex flex-row items-center">
                <Button
                  text="Generate"
                  type="dark"
                  onClick={this.handleReportChecking}
                />
              </div>
            </div>
            <div
              style={{
                height: this.state.mapWidth > 0 ? this.state.mapWidth : 600,
                width: this.state.mapWidth > 0 ? this.state.mapWidth : "100%",
              }}
              id="map-preview"
            >
              <MapView
                defects={this.state.defects}
                inspectionId={this.state.inspection._id}
                mbtileExistance={this.state.mbtileExistance}
                screenshotProps={this.state.screenshotProps}
                mapWidth={this.state.mapWidth}
              />
            </div>

            <DetailList
              className="mt-10"
              title="Site Details"
              list={[
                { title: "Site", text: this.state.site.name },
                { title: "Capacity", text: this.state.site.capacity },
                { title: "Address", text: this.state.site.address.name },
                {
                  title: "Coordinate",
                  text: `${this.state.site.address.lat}, ${this.state.site.address.lng}`,
                },
                { title: "Mount", text: this.state.site.mount },
              ]}
            />

            <DetailList
              className="mt-10"
              title="Inspection Details"
              list={[
                {
                  title: "Inspection Date",
                  text: moment(this.state.inspection.date).format("DD/MM/YYYY"),
                },
                {
                  title: "Inspection Time",
                  text: this.state.inspection.time,
                },
                {
                  title: "Inspector",
                  text: this.state.inspection.person,
                },
                {
                  title: "Sun Irradiation",
                  text: `${this.state.inspection.sunRadiation} 𝑊/𝑚2`,
                },
                {
                  title: "Ambient Temperature",
                  text: `${this.state.inspection.temp} °C`,
                },
                {
                  title: "Wind Speed",
                  text: `${this.state.inspection.wind} m/s`,
                },
                {
                  title: "Weather Condition",
                  text: `${this.state.inspection.weather}`,
                },
                {
                  title: "Drone",
                  text: `${this.state.inspection.drone}`,
                },
              ]}
            />

            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-md leading-6 font-medium text-gray-900 sm:text-lg">
                Defects details
              </h3>
            </div>
            <Table
              hidePagination={true}
              data={this.renderGroupedDefectsList()}
              loading={this.state.loading}
              limit={this.state.defects.length}
              header={[
                { key: "location", title: "Location" },
                { key: "legend", title: "Legend" },
                { key: "priority", title: "Priority" },
                { key: "lat", title: "Lat" },
                { key: "lng", title: "Lng" },
              ]}
            />
          </Container>
        );
      }
    }
  };

  render() {
    return (
      <div className="min-h-screen bg-gray-50 pb-10">
        <Modal
          lib={{ Dialog, Transition }}
          open={this.state.reportLoading}
          renderContent={this.renderReportLoading}
        />
        <Modal
          lib={{ Dialog, Transition }}
          open={this.state.modalVisible}
          title="Report Regenerate"
          renderContent={this.renderContinuousReportGeneration}
          onClose={() => {
            this.setState({
              modalVisible: false,
            });
          }}
        />
        <Header page="Report Detail" history={this.props.history} />
        {this.renderInspection()}
      </div>
    );
  }
}
