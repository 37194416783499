import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import {
  Button,
  Container,
  DetailList,
  EmptyState,
  Loading,
  Modal,
  Select,
  Table,
  Tabs,
  TextButton,
  TextInput,
  Option,
} from "blixify-ui-web/lib";
import moment from "moment";
import { Component, createRef } from "react";
import ReactToPrint from "react-to-print";
import EmptyReport from "../assets/report_empty.jpg";
import Header from "../components/dashboard/Header";
import MapView from "../components/dashboard/MapView";
import { handleDefectsGrouping } from "../components/store/actions/defectActions";
import { Defect, Legend, Progress } from "../model/Defect";
import { Inspection } from "../model/Inspection";
import { Site } from "../model/Site";
import {
  assetAPI,
  defectId,
  defectsRQ,
  defectsWQ,
  inspectionId,
  inspectionsRQ,
  isProd,
  sitesRQ,
  tileUrl,
} from "../utils/clientQuery";
import {
  cumulativeLossCalculation,
  solarCalculation,
} from "../components/store/actions/solarCalculationActions";
import { DefectSquare, GroupedDefects } from "./ReportPreview";
import { PencilIcon, PhotoIcon } from "@heroicons/react/24/outline";

const defectStatusOptions: Option[] = [
  {
    key: "INSPECT",
    label: "To Inspect",
  },
  {
    key: "GOOD",
    label: "Good",
  },
  {
    key: "BAD",
    label: "Bad",
  },
];

interface Props {
  history: any;
}

interface UserLocation {
  lat: number | null;
  lng: number | null;
}

export interface DefectData {
  double: number;
  single: number;
  hot: number;
  cell: number;
}

interface State {
  loading: boolean;
  isEmpty: boolean;
  printLoading: boolean;
  site: Site;
  inspection: Inspection;
  defects: Defect[];
  selectedImage: any;
  groupedDefects: GroupedDefects;
  mbtileExistance: string[];
  userLocation: UserLocation;
  selectedTab: string;
  selectedDefect: Defect | null;
  evidence: File | null;
  defectEdit: boolean;
  editLoading: boolean;
  markerRefresh: boolean;
}

export default class ReportDetail extends Component<Props> {
  private printRef: any = createRef();
  private watchId: any;
  state: State = {
    loading: true,
    isEmpty: true,
    printLoading: false,
    site: {
      _id: "",
      address: {
        lat: 0,
        lng: 0,
        name: "",
      },
      capacity: 0,
      mount: "",
      name: "",
      inverters: [],
      lossFactor: {
        cell: 0,
        double: 0,
        hot: 0,
        single: 0,
      },
      peakHourSunlight: 4,
      ppaRate: 0,
      pvConfiguration: {
        column: 10,
        row: 2,
      },
      pvNumberPerString: 30,
      pvSpec: [],
    },
    inspection: {
      _id: "",
      siteId: "",
      objective: "",
      date: new Date(),
      time: "",
      person: "",
      sunRadiation: 0,
      temp: 0,
      wind: 0,
      weather: "",
      drone: "",
      rgbImage: "",
      thermalImage: "",
    },
    defects: [],
    selectedImage: null,
    groupedDefects: {},
    mbtileExistance: [],
    userLocation: {
      lat: null,
      lng: null,
    },
    selectedTab: "",
    selectedDefect: null,
    evidence: null,
    defectEdit: false,
    editLoading: false,
    markerRefresh: false,
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    try {
      if (selectedId) {
        const inspectionResp = await inspectionsRQ.call({
          type: "get",
          id: selectedId ?? "",
        });
        if (inspectionResp?.data) {
          const siteResp = await sitesRQ.call({
            type: "get",
            id: inspectionResp.data.siteId ?? "",
          });
          const defectsResp = await defectsRQ.call({
            type: "list",
            stopLimit: true,
            query: [
              {
                type: "=",
                queryId: "inspectionId",
                value: inspectionResp.data._id,
              },
            ],
          });
          this.setState(
            {
              inspection: inspectionResp.data,
              site: siteResp?.data,
              defects: defectsResp?.data,
              isEmpty: false,
            },
            async () => {
              const groupedDefects = handleDefectsGrouping(this.state.defects);
              this.setState({
                groupedDefects,
              });
              const keys = ["rgb", "thermal", "plan"];
              for await (const key of keys) {
                try {
                  const res = await axios.get(
                    `${tileUrl}/download/dev?inspectionId=${this.state.inspection._id}&type=${key}`
                  );
                  if (res.data.success) this.state.mbtileExistance.push(key);
                } catch (err) {}
              }
              this.setState({ loading: false }, async () => {
                try {
                  if ("geolocation" in navigator) {
                    const options = {
                      enableHighAccuracy: true,
                      timeout: 5000,
                      maximumAge: 0,
                    };
                    this.watchId = navigator.geolocation.watchPosition(
                      (position) => {
                        const { latitude, longitude, heading, speed } =
                          position.coords;
                        const userLocation = {
                          lat: latitude,
                          lng: longitude,
                          heading: heading,
                          speed: speed,
                        };
                        this.setState({
                          userLocation,
                        });
                      },
                      (err) => {
                        throw err.message;
                      },
                      options
                    );
                  }
                } catch (err) {}
              });
            }
          );
        } else throw "Error";
      } else throw "Error";
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  componentWillUnmount = () => {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  };

  handleImageOnClick = (image?: any) => {
    this.setState({
      selectedImage: image ? image : null,
    });
  };

  handleEditDefect = async () => {
    let imageToken = "";
    const clonedSelectedDefect: Defect = JSON.parse(
      JSON.stringify(this.state.selectedDefect)
    );
    if (this.state.evidence) {
      const photoForm = new FormData();
      const renamedFile = new File([this.state.evidence], "evidence.png", {
        type: this.state.evidence.type,
      });
      photoForm.append("file", renamedFile);
      photoForm.append("data[assetCollectionName]", defectId);
      photoForm.append("data[bucketName]", "airlytic-dev");
      photoForm.append("data[assetFileName]", `evidence.png`);
      photoForm.append("data[assetParentId]", clonedSelectedDefect._id);
      const photoRes = await axios({
        method: "post",
        url: `${assetAPI}/upload`,
        data: photoForm,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!photoRes) throw new Error("Error");
      imageToken = photoRes.data.data;
      clonedSelectedDefect.evidence = imageToken;
    }
    await defectsWQ.call("update", {
      id: clonedSelectedDefect._id,
      data: clonedSelectedDefect,
    });
    const defectIndex = this.state.defects.findIndex(
      (defect) => defect._id === this.state.selectedDefect?._id
    );
    if (defectIndex !== -1) {
      const clonedDefects = JSON.parse(JSON.stringify(this.state.defects));
      clonedDefects[defectIndex] = this.state.selectedDefect;
      this.setState(
        {
          defects: clonedDefects,
          markerRefresh: true,
          selectedDefect: null,
          defectEdit: false,
        },
        () => {
          const groupedDefects = handleDefectsGrouping(this.state.defects);
          this.setState({
            groupedDefects,
          });
        }
      );
    }
  };

  handleSelectedDefectOnChange = (value: string, id: string) => {
    const clonedSelectedDefect = JSON.parse(
      JSON.stringify(this.state.selectedDefect)
    );
    clonedSelectedDefect[id] = value;
    this.setState({
      selectedDefect: clonedSelectedDefect,
    });
  };

  handleModalButtonOnClick = async () => {
    if (this.state.defectEdit) {
      this.setState({
        editLoading: true,
      });
      await this.handleEditDefect();
      this.setState({
        markerRefresh: false,
        editLoading: false,
      });
    } else {
      this.setState({
        defectEdit: true,
      });
    }
  };

  handleSolarCalculation = (zone: string) => {
    const solarResultList: any = [];
    const groupedDefectsSummary: {
      [key: string]: {
        [key: string]: {
          stringNumber: number;
          defectsData: DefectData;
        };
      };
    } = {};

    Object.keys(this.state.groupedDefects[zone]).forEach((key) => {
      const inverter = this.state.site.inverters.filter((inverter) => {
        return inverter.invNumber === Number(key) && inverter.zone === zone;
      });
      let stringNumber = 0;
      if (inverter.length > 0) stringNumber = inverter[0].stringNumber;
      const totalDefectList: Defect[] = [];
      this.state.groupedDefects[zone][key].forEach(
        (eachGroup: DefectSquare) => {
          totalDefectList.push(...eachGroup.defectList);
        }
      );

      if (!groupedDefectsSummary[zone]) {
        groupedDefectsSummary[zone] = {};
      }

      if (!groupedDefectsSummary[zone][key]) {
        groupedDefectsSummary[zone][key] = {
          stringNumber,
          defectsData: {
            double: totalDefectList.filter((defect) => {
              return defect.legend === "DOUBLE";
            }).length,
            single: totalDefectList.filter((defect) => {
              return defect.legend === "SINGLE";
            }).length,
            cell: totalDefectList.filter((defect) => {
              return defect.legend === "CELL";
            }).length,
            hot: totalDefectList.filter((defect) => {
              return defect.legend === "HOT";
            }).length,
          },
        };
      }
      const result = solarCalculation(
        groupedDefectsSummary[zone][key],
        this.state.site
      );
      const inverterData = {
        inverter: `Inverter ${key}`,
        defectsNumber: groupedDefectsSummary[zone][key].defectsData,
        totalSolarDefects: result.totalDefectsNumber,
        stringNumber: stringNumber,
        totalPvNumber: result.totalPvNumber,
        totalStringCapacity: result.totalStringCapacity.toFixed(3),
        normalPvNumber: result.normalPvNumber,
        normalDailyYield: result.normalDailyYield.toFixed(3),
        defectsYield: {
          double: result.defectsYield.double.toFixed(3),
          single: result.defectsYield.single.toFixed(3),
          cell: result.defectsYield.cell.toFixed(3),
          hot: result.defectsYield.hot.toFixed(3),
        },
        totalYield: result.totalYield.toFixed(3),
        specificEnergy: result.droneSpecificEnergy.toFixed(3),
      };
      solarResultList.push(inverterData);
    });
    return solarResultList;
  };

  renderGroupedDefectsList = () => {
    const groupedDefects = JSON.parse(
      JSON.stringify(this.state.groupedDefects)
    );
    const defectsData: any = [];
    const defaultWebUrl = `${assetAPI}/get/airlytic${
      !isProd ? "-dev" : "-prod"
    }/${defectId}`;
    Object.keys(groupedDefects)
      .sort()
      .forEach((zone) => {
        Object.keys(groupedDefects[zone]).forEach((key) => {
          groupedDefects[zone][key].forEach((eachGroup: any) => {
            const fieldKeys = [
              "legend",
              "location",
              "priority",
              "rgb",
              "thermal",
              "plan",
              "coordinates",
              "status",
              "remarks",
              "evidence",
              "edit",
            ];
            const fieldData = fieldKeys.reduce((data: any, field) => {
              if (field === "rgb" || field === "thermal" || field === "plan") {
                const defect = eachGroup.defectList.find(
                  (item: any) => item[field]
                );
                if (defect) {
                  const imageExistance = Object.prototype.hasOwnProperty.call(
                    defect,
                    field
                  );
                  if (imageExistance) {
                    const token = defect[field] ?? "";
                    const imgUrl = `${defaultWebUrl}/${defect._id}/${field}.png?token=${token}`;
                    data[field] = imgUrl ? (
                      <div className="w-36 h-36">
                        <img
                          src={imgUrl}
                          alt={field.toUpperCase()}
                          className="w-full h-full cursor-pointer object-contain"
                          onClick={() => {
                            this.handleImageOnClick(imgUrl);
                          }}
                        />
                      </div>
                    ) : null;
                  } else {
                    return null;
                  }
                }
              } else if (field === "coordinates") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => (
                      <li className="text-xs mt-1 ">{`${defect["lat"]}, ${defect["lng"]}`}</li>
                    ))}
                  </ul>
                );
              } else if (field === "legend") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => (
                      <li className="text-xs mt-1 ">
                        {Legend[defect[field] as keyof typeof Legend]}
                      </li>
                    ))}
                  </ul>
                );
              } else if (field === "status") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => (
                      <li className="text-xs mt-1 ">
                        {defect["progress"]
                          ? Progress[
                              defect["progress"] as keyof typeof Progress
                            ]
                          : "-"}
                      </li>
                    ))}
                  </ul>
                );
              } else if (field === "remarks") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => (
                      <li className="text-xs mt-1 ">{defect[field] ?? "-"}</li>
                    ))}
                  </ul>
                );
              } else if (field === "evidence") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => {
                      const token = defect[field] ?? "";
                      const imgUrl = `${defaultWebUrl}/${defect._id}/${field}.png?token=${token}`;
                      return token ? (
                        <li>
                          <PhotoIcon
                            className="block h-5 w-5 text-primary-800 hover:text-primary-600 cursor-pointer"
                            onClick={() => {
                              this.handleImageOnClick(imgUrl);
                            }}
                          />
                        </li>
                      ) : (
                        <li>{"-"}</li>
                      );
                    })}
                  </ul>
                );
              } else if (field === "edit") {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => {
                      return (
                        <li>
                          <PencilIcon
                            className="block h-5 w-5 text-primary-800 hover:text-primary-600 cursor-pointer"
                            onClick={() => {
                              this.setState({
                                selectedDefect: defect,
                              });
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                );
              } else {
                data[field] = (
                  <ul>
                    {eachGroup.defectList.map((defect: any) => (
                      <li className="text-xs mt-1 ">{defect[field]}</li>
                    ))}
                  </ul>
                );
              }
              return data;
            }, {});

            defectsData.push(fieldData);
          });
        });
      });
    return defectsData;
  };

  renderModalImage = () => {
    return (
      <div className="flex flex-row items-center justify-center">
        <img
          src={this.state.selectedImage}
          alt="Selected by user"
          className="w-60 h-48"
        />
      </div>
    );
  };
  renderPrintLoading = () => {
    return (
      <div className="flex flex-col items-center">
        <Loading />
        <div className="mt-8 text-md leading-6 font-medium text-gray-900 sm:text-lg">
          Please wait while we are printing the report
        </div>
      </div>
    );
  };

  renderDefectEditModal = () => {
    const titleClassName = "text-base font-semibold text-gray-700";
    const descriptionClassName = "text-sm text-gray-700 mt-1";
    const defaultWebUrl = `${assetAPI}/get/airlytic${
      !isProd ? "-dev" : "-prod"
    }/${defectId}`;
    const selectedId = this.state.selectedDefect?._id;
    const evidenceUrl = `${defaultWebUrl}/${selectedId}/evidence.png?token=${this.state.selectedDefect?.evidence}`;
    if (this.state.editLoading) {
      return (
        <div className="flex flex-col items-center">
          <Loading />
          <div className="mt-8 text-md leading-6 font-medium text-gray-900 sm:text-lg">
            Please wait while we are editing the defect
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col w-full">
            <span className={titleClassName}>
              {this.state.selectedDefect?.location}
            </span>
            <span className={descriptionClassName}>
              {Legend[this.state.selectedDefect?.legend ?? "CELL"]}
            </span>
            <span
              className={descriptionClassName}
            >{`${this.state.selectedDefect?.lat},${this.state.selectedDefect?.lng}`}</span>
            <div className="mt-2">
              {this.state.defectEdit ? (
                <div className="flex flex-row justify-center items-center mt-2">
                  <Select
                    value={this.state.selectedDefect?.progress ?? "INSPECT"}
                    label="Status"
                    options={defectStatusOptions}
                    onChange={(value) => {
                      this.handleSelectedDefectOnChange(value, "progress");
                    }}
                    type="icon"
                  />
                </div>
              ) : (
                <span className={descriptionClassName}>
                  Status:{" "}
                  {Progress[this.state.selectedDefect?.progress ?? "INSPECT"]}
                </span>
              )}
            </div>
            <div className="mt-2">
              {this.state.defectEdit ? (
                <TextInput
                  label="Remarks"
                  placeholder="Remarks"
                  type="text"
                  id="remarks"
                  value={this.state.selectedDefect?.remarks}
                  onChange={(e) => {
                    this.handleSelectedDefectOnChange(
                      e.target.value,
                      e.target.id
                    );
                  }}
                />
              ) : (
                <span className={descriptionClassName}>
                  Remarks: {this.state.selectedDefect?.remarks}
                </span>
              )}
            </div>
            <div className="mt-2">
              <label
                htmlFor="evidence"
                className="block mb-1 text-sm font-medium text-gray-900"
              >
                Evidence
              </label>
              {this.state.defectEdit ? (
                <>
                  <input
                    type="file"
                    id="evidence"
                    accept="image/*"
                    capture
                    title="camera"
                    onChange={(e: any) => {
                      this.setState({
                        evidence: e.target.files[0],
                      });
                    }}
                  />
                </>
              ) : (
                <div>
                  {this.state.selectedDefect?.evidence ? (
                    <div className="w-36 h-36">
                      <img
                        src={evidenceUrl}
                        alt={"evidence"}
                        className="w-full h-full cursor-pointer object-contain"
                      />
                    </div>
                  ) : (
                    <div>
                      <span className={descriptionClassName}>N/A</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-row-reverse mt-2">
              <TextButton
                text={this.state.defectEdit ? "Submit" : "Edit"}
                onClick={this.handleModalButtonOnClick}
                className="text-primary-800 text-sm font-bold"
              />
              <div className="flex-1" />
              {!this.state.defectEdit ? (
                <TextButton
                  text="Navigate"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${this.state.selectedDefect?.lat},${this.state.selectedDefect?.lng}`,
                      "_blank"
                    );
                  }}
                  className="text-primary-800 text-sm font-bold"
                />
              ) : (
                <TextButton
                  text="Back"
                  onClick={() => {
                    this.setState({
                      defectEdit: false,
                    });
                  }}
                  className="text-primary-800 text-sm font-bold"
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  renderDefectsSummaryTable = () => {
    const zoneDefectSummary: any[] = [];
    Object.keys(this.state.groupedDefects)
      .sort()
      .forEach((zone) => {
        let defectsSummary: {
          zone: string;
          toInspect: number;
          good: number;
          bad: number;
        } = {
          zone: "",
          toInspect: 0,
          bad: 0,
          good: 0,
        };
        const totalDefectList: Defect[] = [];

        Object.keys(this.state.groupedDefects[zone]).forEach((inv) => {
          this.state.groupedDefects[zone][inv].forEach(
            (eachGroup: DefectSquare) => {
              totalDefectList.push(...eachGroup.defectList);
            }
          );
        });
        defectsSummary = {
          zone,
          toInspect: totalDefectList.filter((eachDefect) => {
            return (
              eachDefect.progress === undefined ||
              eachDefect.progress === "INSPECT"
            );
          }).length,
          bad: totalDefectList.filter((eachDefect) => {
            return eachDefect.progress === "BAD";
          }).length,
          good: totalDefectList.filter((eachDefect) => {
            return eachDefect.progress === "GOOD";
          }).length,
        };
        zoneDefectSummary.push(defectsSummary);
      });

    return (
      <Table
        hidePagination={true}
        data={zoneDefectSummary}
        loading={this.state.loading}
        limit={zoneDefectSummary.length}
        header={[
          { key: "zone", title: "Zone" },
          { key: "toInspect", title: "To Inspect" },
          { key: "good", title: "Good" },
          { key: "bad", title: "Bad" },
        ]}
      />
    );
  };

  renderCumulativeLossTable = () => {
    const cumulativeLossDataList: any = [];
    Object.keys(this.state.groupedDefects)
      .sort()
      .forEach((zone) => {
        let defectsNumber: DefectData = {
          cell: 0,
          single: 0,
          double: 0,
          hot: 0,
        };
        const totalDefectList: Defect[] = [];

        Object.keys(this.state.groupedDefects[zone]).forEach((inv) => {
          this.state.groupedDefects[zone][inv].forEach(
            (eachGroup: DefectSquare) => {
              totalDefectList.push(...eachGroup.defectList);
            }
          );
        });
        defectsNumber = {
          double: totalDefectList.filter((defect) => {
            return defect.legend === "DOUBLE";
          }).length,
          single: totalDefectList.filter((defect) => {
            return defect.legend === "SINGLE";
          }).length,
          cell: totalDefectList.filter((defect) => {
            return defect.legend === "CELL";
          }).length,
          hot: totalDefectList.filter((defect) => {
            return defect.legend === "HOT";
          }).length,
        };
        const cumulativeLossData = cumulativeLossCalculation(
          zone,
          defectsNumber,
          this.state.site
        );
        cumulativeLossDataList.push(cumulativeLossData);
      });
    return (
      <Table
        hidePagination={true}
        data={cumulativeLossDataList}
        loading={this.state.loading}
        limit={cumulativeLossDataList.length}
        header={[
          { key: "zone", title: "Zone" },
          {
            key: "defectsNumber",
            title: "Defects Number",
            groupData: [
              { key: "double", title: "Double Diode" },
              { key: "single", title: "Single Diode" },
              { key: "cell", title: "Multi Cell" },
              { key: "hot", title: "Hotspot" },
            ],
          },
          { key: "totalDefectsNumber", title: "Total Defects" },
          {
            key: "defectsYield",
            title: "Defects Yield (kW)",
            groupData: [
              { key: "double", title: "Double Diode" },
              { key: "single", title: "Single Diode" },
              { key: "cell", title: "Multi Cell" },
              { key: "hot", title: "Hotspot" },
            ],
          },
          {
            key: "estimatedCumulativeLoss",
            title: "Estimated Cumulative Loss (kW/Annum)",
          },
          {
            key: "estimatedRMCumulativeLoss",
            title: "Estimated Cumulative Loss (RM/Annum)",
          },
        ]}
      />
    );
  };

  renderSolarCalculationTablePerZone = () => {
    if (this.state.printLoading) {
      return (
        <>
          {Object.keys(this.state.groupedDefects)
            .sort()
            .map((key) => (
              <>
                <div className="px-4 py-2 mt-3 sm:px-6">
                  <h3 className="text-sm leading-6 font-medium text-gray-900 sm:text-md">
                    {`Zone ${key}`}
                  </h3>
                </div>
                <Table
                  hidePagination={true}
                  data={this.handleSolarCalculation(key)}
                  loading={this.state.loading}
                  limit={this.handleSolarCalculation(key).length}
                  header={[
                    { key: "inverter", title: "Inverter" },
                    {
                      key: "defectsNumber",
                      title: "Defects Number",
                      groupData: [
                        { key: "double", title: "Double Diode" },
                        { key: "single", title: "Single Diode" },
                        { key: "cell", title: "Multi Cell" },
                        { key: "hot", title: "Hotspot" },
                      ],
                    },
                    { key: "totalSolarDefects", title: "Total Defects" },
                  ]}
                />

                <Table
                  hidePagination={true}
                  data={this.handleSolarCalculation(key)}
                  loading={this.state.loading}
                  limit={this.handleSolarCalculation(key).length}
                  header={[
                    { key: "inverter", title: "Inverter" },
                    { key: "stringNumber", title: "No. of String" },
                    { key: "totalPvNumber", title: "Total PV Number" },
                    {
                      key: "totalStringCapacity",
                      title: "Total Capacity (kWp)",
                    },
                    { key: "normalPvNumber", title: "No. of normal PV" },
                    {
                      key: "normalDailyYield",
                      title: "Normal Daily Yield (kWh)",
                    },
                    {
                      key: "defectsYield",
                      title: "Defects Yield (kW)",
                      groupData: [
                        { key: "double", title: "Double Diode" },
                        { key: "single", title: "Single Diode" },
                        { key: "cell", title: "Multi Cell" },
                        { key: "hot", title: "Hotspot" },
                      ],
                    },
                    { key: "totalYield", title: "Total Yield (kWh)" },
                    { key: "specificEnergy", title: "Specific Energy" },
                  ]}
                />
              </>
            ))}
        </>
      );
    } else {
      const keys = Object.keys(this.state.groupedDefects).sort();
      const tabs = keys.map((key) => {
        return { id: key, name: `Zone ${key}`, href: "#" };
      });
      return (
        <>
          <Tabs
            selectedId={this.state.selectedTab || keys[0]}
            tabs={tabs}
            onClick={(id) => {
              this.setState({
                selectedTab: id,
              });
            }}
          />
          <Table
            hidePagination={true}
            data={this.handleSolarCalculation(
              this.state.selectedTab || keys[0]
            )}
            loading={this.state.loading}
            limit={
              this.handleSolarCalculation(this.state.selectedTab || keys[0])
                .length
            }
            header={[
              { key: "inverter", title: "Inverter" },
              {
                key: "defectsNumber",
                title: "Defects Number",
                groupData: [
                  { key: "double", title: "Double Diode" },
                  { key: "single", title: "Single Diode" },
                  { key: "cell", title: "Multi Cell" },
                  { key: "hot", title: "Hotspot" },
                ],
              },
              { key: "totalSolarDefects", title: "Total Defects" },
            ]}
          />

          <Table
            hidePagination={true}
            data={this.handleSolarCalculation(
              this.state.selectedTab || keys[0]
            )}
            loading={this.state.loading}
            limit={
              this.handleSolarCalculation(this.state.selectedTab || keys[0])
                .length
            }
            header={[
              { key: "inverter", title: "Inverter" },
              { key: "stringNumber", title: "No. of String" },
              { key: "totalPvNumber", title: "Total PV Number" },
              { key: "totalStringCapacity", title: "Total Capacity (kWp)" },
              { key: "normalPvNumber", title: "No. of normal PV" },
              {
                key: "normalDailyYield",
                title: "Normal Daily Yield (kWh)",
              },
              {
                key: "defectsYield",
                title: "Defects Yield (kW)",
                groupData: [
                  { key: "double", title: "Double Diode" },
                  { key: "single", title: "Single Diode" },
                  { key: "cell", title: "Multi Cell" },
                  { key: "hot", title: "Hotspot" },
                ],
              },
              { key: "totalYield", title: "Total Yield (kWh)" },
              { key: "specificEnergy", title: "Specific Energy" },
            ]}
          />
        </>
      );
    }
  };

  renderInspection = () => {
    if (this.state.loading) {
      return (
        <div className="mt-24 flex flex-col items-center">
          <Loading />
          <div className="mt-8 text-md leading-6 font-medium text-gray-900 sm:text-lg">
            Please wait while we are previewing the report
          </div>
        </div>
      );
    } else {
      if (this.state.isEmpty) {
        return (
          <EmptyState
            className="mt-12 mx-32 bg-white"
            icon={<img src={EmptyReport} className="w-48" alt="Empty state" />}
            text="The report is unavailable. Please make sure you are visiting the correct report link."
          />
        );
      } else {
        let defectTableHeader = [
          { key: "plan", title: "PV Layout" },
          { key: "thermal", title: "Thermal" },
          { key: "rgb", title: "Visual" },
          { key: "legend", title: "Legend" },
          { key: "location", title: "Location" },
          { key: "status", title: "Status" },
          { key: "remarks", title: "Remarks" },
        ];

        if (!this.state.printLoading) {
          defectTableHeader = defectTableHeader.concat([
            { key: "evidence", title: "Evidence" },
            { key: "priority", title: "Priority" },
            { key: "coordinates", title: "Coordinates" },
            { key: "edit", title: "Edit" },
          ]);
        }
        const defaultWebUrl = `${assetAPI}/get/airlytic${
          !isProd ? "-dev" : "-prod"
        }/${inspectionId}`;
        const rgbImage = `${defaultWebUrl}/${this.state.inspection._id}/rgb.png?token=${this.state.inspection.rgbImage}`;
        const thermalImage = `${defaultWebUrl}/${this.state.inspection._id}/thermal.png?token=${this.state.inspection.thermalImage}`;
        return (
          <Container className="bg-gray-50">
            <div className="flex flex-row justify-end mt-10">
              <ReactToPrint
                pageStyle={
                  ".pdfInspection{margin-top: 300px !important} .printHide{display:none !important}"
                }
                onBeforeGetContent={() => {
                  return new Promise<void>((resolve, reject) => {
                    this.setState({ printLoading: true }, () => resolve());
                  });
                }}
                onAfterPrint={() => {
                  this.setState({ printLoading: false });
                }}
                documentTitle={`${this.state.site.name} Inspection - ${moment(
                  this.state.inspection.date
                ).format("DD-MM-YYYY")}`}
                trigger={() => {
                  return <Button text="Download PDF" type="normal" />;
                }}
                content={() => this.printRef}
              />
            </div>
            <div ref={(el) => (this.printRef = el)}>
              <div className="mt-10 pb-8 flex flex-col sm:items-center sm:flex-row">
                <div>
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                    {`${this.state.site.name} Inspection - ${moment(
                      this.state.inspection.date
                    ).format("DD/MM/YYYY")}`}
                  </h1>
                </div>
              </div>
              <div className="printHide w-full" style={{ height: 600 }}>
                <MapView
                  defects={this.state.defects}
                  inspectionId={this.state.inspection._id}
                  mbtileExistance={this.state.mbtileExistance}
                  userLocation={this.state.userLocation}
                  markerRefresh={this.state.markerRefresh}
                  handleMarkerOnClick={(defect: Defect) => {
                    this.setState({
                      selectedDefect: defect,
                    });
                  }}
                />
              </div>
              <DetailList
                className="mt-10"
                title="Site Details"
                list={[
                  { title: "Site", text: this.state.site.name },
                  {
                    title: "Capacity",
                    text: `${this.state.site.capacity} MWp `,
                  },
                  { title: "Address", text: this.state.site.address.name },
                  {
                    title: "Coordinate",
                    text: `${this.state.site.address.lat}, ${this.state.site.address.lng}`,
                  },
                  { title: "Mount", text: this.state.site.mount },
                ]}
              />
              <div className="flex w-full lg:flex-row flex-col">
                <img
                  className="lg:w-2/4 w-full mt-5"
                  src={rgbImage}
                  alt="Cover for site RGB"
                />
                <img
                  className="lg:w-2/4 sm:w-full"
                  src={thermalImage}
                  alt="Cover for site Thermal"
                />
              </div>
              <DetailList
                className="mt-10 pdfInspection"
                title="Inspection Details"
                list={[
                  {
                    title: "Inspection Date",
                    text: moment(this.state.inspection.date).format(
                      "DD/MM/YYYY"
                    ),
                  },
                  {
                    title: "Inspection Time",
                    text: this.state.inspection.time,
                  },
                  {
                    title: "Inspector",
                    text: this.state.inspection.person,
                  },
                  {
                    title: "Sun Irradiation",
                    text: `${this.state.inspection.sunRadiation} 𝑊/𝑚2`,
                  },
                  {
                    title: "Ambient Temperature",
                    text: `${this.state.inspection.temp} °C`,
                  },
                  {
                    title: "Wind Speed",
                    text: `${this.state.inspection.wind} m/s`,
                  },
                  {
                    title: "Weather Condition",
                    text: `${this.state.inspection.weather}`,
                  },
                  {
                    title: "Drone",
                    text: `${this.state.inspection.drone}`,
                  },
                ]}
              />

              <h3 className="text-xl pt-16 pb-2 leading-6 font-bold text-gray-900">
                Defects Summary Table
              </h3>
              {this.renderDefectsSummaryTable()}

              <h3 className="text-xl pt-16 pb-2 leading-6 font-bold text-gray-900">
                Power Loss Analysis
              </h3>
              {this.renderCumulativeLossTable()}

              <h3 className="text-xl pt-16 pb-2 leading-6 font-bold text-gray-900">
                Inverter Analysis
              </h3>
              {this.renderSolarCalculationTablePerZone()}

              <h3 className="text-xl pt-16 pb-2 leading-6 font-bold text-gray-900">
                Defects details
              </h3>
              <Table
                hidePagination={true}
                data={this.renderGroupedDefectsList()}
                loading={this.state.loading}
                limit={this.state.defects.length}
                header={defectTableHeader}
              />
            </div>
          </Container>
        );
      }
    }
  };

  render() {
    return (
      <div className="min-h-screen bg-gray-50 pb-10">
        <Modal
          title="Image"
          open={this.state.selectedImage ? true : false}
          lib={{ Dialog, Transition }}
          renderContent={this.renderModalImage}
          onClose={this.handleImageOnClick.bind(this, null)}
        />
        <Modal
          open={this.state.printLoading}
          lib={{ Dialog, Transition }}
          renderContent={this.renderPrintLoading}
        />
        <Modal
          open={this.state.selectedDefect ? true : false}
          lib={{ Dialog, Transition }}
          renderContent={this.renderDefectEditModal}
          onClose={() => {
            this.setState({
              selectedDefect: null,
            });
          }}
        />
        <Header page="Report Detail" history={this.props.history} />
        {this.renderInspection()}
      </div>
    );
  }
}
