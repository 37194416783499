import React, { Component } from "react";

interface Props {
  id?: string;
  label?: string;
  type?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  accept?: string;
  error?: string;
  onChange?: (e: any) => void;
}

export default class Input extends Component<Props> {
  handleOnChange = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    let defaultClassName =
      "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 focus:border-gray-500 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    if (this.props.error) {
      defaultClassName += " mb-0.5 border-red-300";
    }

    return (
      <>
        <label className="sr-only">{this.props.label}</label>
        <input
          id={this.props.id}
          className={defaultClassName}
          accept={this.props.accept}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.handleOnChange}
          type={this.props.type}
        />
        <span className="text-red-500 text-sm">{this.props.error}</span>
      </>
    );
  }
}
