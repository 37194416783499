import { Site } from "../../../model/Site";
import { DefectData } from "../../../pages/ReportDetail";

export const solarCalculation = (
  inverter: {
    stringNumber: number;
    defectsData: DefectData;
  },
  site: Site
) => {
  type DefectsDataKey = keyof DefectData;
  const pvModulePerString = site.pvNumberPerString;
  const jinkoPvPower = 0.385;
  const longiPvPower = 0.44;
  const stringNumber = inverter.stringNumber;
  const defectsYield: DefectData = {
    cell: 0,
    double: 0,
    single: 0,
    hot: 0,
  };
  const solarDefects = inverter.defectsData;

  const totalDefectsNumber = Object.values(inverter.defectsData).reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const totalPvNumber = stringNumber * pvModulePerString;
  const totalStringCapacity =
    stringNumber > 18
      ? (stringNumber - 18) * pvModulePerString * longiPvPower +
        18 * pvModulePerString * jinkoPvPower
      : stringNumber * pvModulePerString * jinkoPvPower;
  const normalPvNumber = totalPvNumber - totalDefectsNumber;
  const normalDailyYield =
    stringNumber > 18
      ? ((stringNumber - 18) * pvModulePerString * longiPvPower +
          (18 * pvModulePerString - totalDefectsNumber) * jinkoPvPower) *
        4
      : (stringNumber * pvModulePerString - totalDefectsNumber) *
        jinkoPvPower *
        4;

  Object.keys(defectsYield).forEach((key) => {
    const ratio = 1 - site.lossFactor[key as DefectsDataKey];
    defectsYield[key as DefectsDataKey] =
      ratio * jinkoPvPower * solarDefects[key as DefectsDataKey] * 4;
  });

  const totalYield = Object.values(defectsYield).reduce(
    (acc, value) => acc + value,
    normalDailyYield
  );

  const droneSpecificEnergy = totalYield / totalStringCapacity;

  const data = {
    stringNumber,
    totalPvNumber,
    totalStringCapacity,
    totalDefectsNumber,
    normalPvNumber,
    normalDailyYield,
    defectsYield,
    totalYield,
    droneSpecificEnergy,
  };
  return data;
};

export const cumulativeLossCalculation = (
  zone: string,
  defectsData: DefectData,
  site: Site
) => {
  type DefectsDataKey = keyof DefectData;
  const defectsYield: DefectData = {
    cell: 0,
    double: 0,
    single: 0,
    hot: 0,
  };
  const totalDefectsNumber = Object.values(defectsData).reduce(
    (acc: any, value: any) => acc + value,
    0
  );
  Object.keys(defectsYield).forEach((key) => {
    const ratio = site.lossFactor[key as DefectsDataKey];
    defectsYield[key as DefectsDataKey] =
      ratio * 0.36 * defectsData[key as DefectsDataKey] * 4;
  });

  const totalYield = Object.values(defectsYield).reduce(
    (acc, value) => acc + value,
    0
  );

  const estimatedCumulativeLoss = totalYield * 365;
  const estimatedRMCumulativeLoss = estimatedCumulativeLoss * site.ppaRate;
  const data = {
    zone,
    defectsNumber: defectsData,
    totalDefectsNumber,
    defectsYield: {
      double: defectsYield.double.toFixed(3),
      single: defectsYield.single.toFixed(3),
      cell: defectsYield.cell.toFixed(3),
      hot: defectsYield.hot.toFixed(3),
    },
    estimatedCumulativeLoss: estimatedCumulativeLoss.toFixed(3),
    estimatedRMCumulativeLoss: estimatedRMCumulativeLoss.toFixed(2),
  };
  return data;
};
