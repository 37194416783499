import Base from "./base";

export enum Legend {
  "SINGLE" = "Single Diode",
  "DOUBLE" = "Double Diode",
  "CELL" = "Cell & Multi Cell",
  "HOT" = "Hot Spot",
}

export enum Priority {
  "HIGH" = "High",
  "MEDIUM" = "Medium",
  "LOW" = "Low",
}

export enum Progress {
  "INSPECT" = "To inspect",
  "GOOD" = "Good",
  "BAD" = "Bad",
}

/**
 * Collection : defects
 *
 * id - UUID
 * inspectionId - string
 * legend - Legend Type
 * lat - GPS Lat
 * lng - GPS Lng
 */
export interface Defect extends Base {
  _id: string;
  location: string;
  inspectionId: string;
  priority: keyof typeof Priority;
  legend: keyof typeof Legend;
  progress: keyof typeof Progress;
  lat: number;
  lng: number;
  remarks?: string;
  rgb?: string;
  plan?: string;
  thermal?: string;
  evidence?: string;
}
