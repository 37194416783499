import React, { Component } from "react";
import { Link } from "react-router-dom";
import Agriculture from "../assets/industry_agriculture.jpg";
import Construction from "../assets/industry_construction.jpg";
import Infrastructure from "../assets/industry_infrastructure.jpg";
import AerialDataAcquisition from "../assets/step_acquisition.png";
import Analysis from "../assets/step_analysis.png";
import Reporting from "../assets/step_reporting.png";
import Footer from "../components/Footer";
import Header, { Pages } from "../components/Header";
import Hero from "../components/marketing/home/Hero";

const industries = [
  {
    title: "Agriculture",
    desc: "We provide precision agriculture solutions that focus on remote sensing and geospatial analytics on farms and plantations to increase yield and productivity.",
    img: Agriculture,
  },
  {
    title: "Infrastructure",
    desc: "Reduce the hassle of manual labouring on inspection of infrastructure by using high-resolution drone cameras for better visualization.",
    img: Infrastructure,
  },
  {
    title: "Construction",
    desc: "Conduct topography mapping and lands surveys during the pre-construction phase and provide more visibility on building work progress via aerial imagery ",
    img: Construction,
  },
];

export default class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="relative bg-gray-900 overflow-hidden">
          <Header page={Pages.HOME} />
          <Hero
            title="Increase Productivity "
            titleCyan="Via Aerial Analytics"
            description="Airlytic provides an end-to-end comprehensive solutions in streamlining industrial workflow via aerial imaging solutions"
            landing={true}
          />
          <div className="mx-auto px-4 max-w-md sm:max-w-7xl lg:px-8 text-white">
            <div className="flex flex-col items-center justify-center -mt-16 sm:my-8  font-semibold">
              <span className="text-3xl sm:text-4xl tracking-tight font-extrabold">
                How We Work
              </span>
              <span className="mt-2 text-gray-300 font-medium text-base sm:text-xl">
                Airlytic provides seamless analytics solution via 3 simple steps
              </span>
            </div>
            <div className="grid grid-cols-1 gap-y-4 my-12 gap-x-8 items-center md:grid-cols-3">
              <div className="flex flex-col items-center">
                <img src={AerialDataAcquisition} alt="" width={120} />
                <span className="mt-4">Step 1 :Aerial Data Acquisition</span>
              </div>
              <div className="flex flex-col items-center">
                <img src={Analysis} alt="" width={120} />
                <span className="mt-4">Step 2 :Data processing</span>
              </div>
              <div className="flex flex-col items-center">
                <img src={Reporting} alt="" width={120} />
                <span className="mt-4">Step 3 :Analytics Report</span>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl text-white my-24 sm:my-36 lg:px-8 ">
            <div className="flex flex-col items-center justify-center my-8 font-semibold">
              <span className="text-3xl sm:text-4xl tracking-tight font-bold">
                Industries We Work With
              </span>
            </div>
            <div className="grid grid-cols-1 gap-8 mx-12 md:grid-cols-2 lg:grid-cols-3">
              {industries.map((eachIndustry) => {
                return (
                  <div className="bg-gray-800 rounded-xl">
                    <div className="flex flex-col justify-center items-center m-8 rounded-md">
                      <img
                        src={eachIndustry.img}
                        alt=""
                        className="w-full h-48 object-cover rounded-xl"
                      />
                      <div className="flex flex-col justify-center items-center min-h-48 my-4">
                        <span className="text-2xl my-4">
                          {eachIndustry.title}
                        </span>
                        <span className="text-base text-justify leading-8">
                          {eachIndustry.desc}
                        </span>
                      </div>
                      <Link
                        className="inline-flex items-center px-8 py-1 rounded-full text-lg font-medium bg-sky-500 text-white mt-4"
                        to="/services"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
