import { ArrowsPointingOutIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { IconButton } from "blixify-ui-web/lib";
import L from "leaflet";
import { Component, createRef } from "react";
import { createRoot } from "react-dom/client";
import DoubleDiode from "../../assets/double_diode.png";
import Hotspot from "../../assets/hotspot.png";
import UserLocationIcon from "../../assets/map_user_icon.png";
import MultiCell from "../../assets/multicell.png";
import SingleDiode from "../../assets/single_diode.png";
import { Defect } from "../../model/Defect";
import {
  CustomTileLayerOptions,
  ScreenshotProps,
} from "../../pages/ReportPreview";
import { tileUrl } from "../../utils/clientQuery";
import CustomPopup, { TilesVisible } from "../base/CustomPopup";
import { sleep } from "../../utils";

interface Props {
  defects: Defect[];
  mbtileExistance: string[];
  inspectionId?: string;
  userLocation?: {
    lat: number | null;
    lng: number | null;
  };
  lat?: number;
  lng?: number;
  type?: string;
  screenshotProps?: ScreenshotProps;
  mapWidth?: number;
  markerRefresh?: boolean;
  handleMarkerOnClick?: (defect: Defect) => void;
}

interface State {
  markerList: any;
  tilesVisible: TilesVisible;
}

export default class MapView extends Component<Props> {
  private map = createRef<HTMLDivElement>();
  private leafletMap: any = "";
  private googleMapTiles: any = "";
  private rgbTiles: any = "";
  private thermalTiles: any = "";
  private planTiles: any = "";
  private tilesLayer: any = "";
  private marker: any = null;
  private accuracyOverlay: any = null;

  state: State = {
    markerList: [],
    tilesVisible: {
      plan: false,
      rgb: false,
      thermal: false,
    },
  };

  componentDidMount = () => {
    if (this.map.current) {
      this.leafletMap = L.map(this.map.current, {
        zoomControl: false,
        attributionControl: false,
      }).setView(
        [this.props.lat ?? 4.476128, this.props.lng ?? 101.149894],
        20
      );
      // Add a tile layer
      this.googleMapTiles = L.tileLayer(
        "//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          attribution: "Map data: &copy; Google Maps",
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
          maxNativeZoom: 21,
          maxZoom: 99,
          minZoom: 0,
        }
      ).addTo(this.leafletMap);

      if (this.props.defects.length > 0) {
        this.handleZoomToDefects();
        const markerList = this.handleRenderMarker(this.props.defects);
        this.setState({
          markerList,
        });
      }
      this.handleRenderUserLocationMarker();
      this.handleInitOverlay();
    }
  };

  componentDidUpdate = async (prevProps: Readonly<Props>) => {
    if (
      prevProps.defects.length !== this.props.defects.length ||
      (prevProps.markerRefresh !== this.props.markerRefresh &&
        this.props.markerRefresh === true)
    ) {
      const markerList = this.handleRenderMarker(this.props.defects);
      this.setState({
        markerList,
      });
    }

    if (
      this.props.userLocation !== undefined &&
      prevProps.userLocation?.lat !== this.props.userLocation?.lat &&
      prevProps.userLocation?.lng !== this.props.userLocation.lng
    ) {
      this.handleRenderUserLocationMarker();
    }

    if (
      this.props.screenshotProps?.bounds !== prevProps.screenshotProps?.bounds
    ) {
      await this.leafletMap.invalidateSize();
      if (this.googleMapTiles) this.leafletMap.removeLayer(this.googleMapTiles);
      if (this.tilesLayer) this.leafletMap.removeLayer(this.tilesLayer);
      if (this.planTiles) this.leafletMap.removeLayer(this.planTiles);

      if (this.state.markerList.length > 0) {
        this.state.markerList.forEach((marker: any) => {
          marker.remove();
        });
        this.setState({
          markerList: [],
        });
      }
      const options: CustomTileLayerOptions = {
        maxNativeZoom: 21,
        maxZoom: 30,
        minZoom: 15,
        inspectionId: this.props.inspectionId ?? "",
        type: this.props.screenshotProps?.type ?? "",
      };
      if (this.props.screenshotProps?.type === "plan") {
        const planOptions: CustomTileLayerOptions = {
          maxNativeZoom: 21,
          maxZoom: 30,
          minZoom: 15,
          inspectionId: this.props.inspectionId ?? "",
          type: this.props.screenshotProps?.type ?? "",
          opacity: 0.5,
        };
        this.tilesLayer = L.tileLayer(
          `${tileUrl}/tiles/dev?x={x}&y={y}&z={z}&inspectionId={inspectionId}&type=thermal`,
          options
        ).addTo(this.leafletMap);

        this.planTiles = L.tileLayer(
          `${tileUrl}/tiles/dev?x={x}&y={y}&z={z}&inspectionId={inspectionId}&type={type}`,
          planOptions
        ).addTo(this.leafletMap);
      } else {
        this.tilesLayer = L.tileLayer(
          `${tileUrl}/tiles/dev?x={x}&y={y}&z={z}&inspectionId={inspectionId}&type={type}`,
          options
        ).addTo(this.leafletMap);
      }
      this.leafletMap.fitBounds(this.props.screenshotProps?.bounds);
      const markerList = this.handleRenderMarker(
        this.props.screenshotProps?.defectList ?? []
      );
      this.setState({
        markerList,
      });
    }
  };

  handleInitOverlay = async () => {
    this.handleTilesToggle("thermal");
    await sleep(500);
    this.handleTilesToggle("plan");
  };

  handleZoomToDefects = () => {
    if (this.props.defects.length > 0) {
      const lats = this.props.defects.map((item) => item.lat);
      const lngs = this.props.defects.map((item) => item.lng);

      let minLat = Math.min(...lats);
      let minLng = Math.min(...lngs);
      let maxLat = Math.max(...lats);
      let maxLng = Math.max(...lngs);
      const latLngBounds = L.latLngBounds([
        [minLat, minLng],
        [maxLat, maxLng],
      ]);
      this.leafletMap.fitBounds(latLngBounds);
    }
  };

  handleRenderMarker = (defects: Defect[]) => {
    const imageList = {
      SINGLE: SingleDiode,
      DOUBLE: DoubleDiode,
      CELL: MultiCell,
      HOT: Hotspot,
    };
    const markerList = defects.map((defect) => {
      const icon = L.icon({
        iconUrl: imageList[defect.legend],
        iconSize: [10, 10],
      });
      const marker = L.marker([defect.lat, defect.lng], { icon }).addTo(
        this.leafletMap
      );
      if (this.props.handleMarkerOnClick) {
        marker.on("click", () => {
          this.props.handleMarkerOnClick?.(defect);
        });
      }
      return marker;
    });
    return markerList;
  };

  handleTilesToggle = (type: string) => {
    const option = {
      maxNativeZoom: 21,
      maxZoom: 30,
      minZoom: 15,
      inspectionId: this.props.inspectionId,
      type,
      opacity: type === "plan" ? 0.5 : 1,
    };
    const tilesVisible: { [key: string]: boolean } = {
      ...this.state.tilesVisible,
    };
    tilesVisible[type] = !tilesVisible[type];

    if (tilesVisible[type]) {
      const layer = L.tileLayer(
        `${tileUrl}/tiles/dev?x={x}&y={y}&z={z}&inspectionId={inspectionId}&type={type}`,
        option
      ).addTo(this.leafletMap);

      switch (type) {
        case "rgb":
          this.rgbTiles = layer;
          break;
        case "thermal":
          this.thermalTiles = layer;
          break;
        case "plan":
          this.planTiles = layer;
          break;
      }
    } else {
      switch (type) {
        case "rgb":
          this.leafletMap.removeLayer(this.rgbTiles);
          this.rgbTiles = null;
          break;
        case "thermal":
          this.leafletMap.removeLayer(this.thermalTiles);
          this.thermalTiles = null;
          break;
        case "plan":
          this.leafletMap.removeLayer(this.planTiles);
          this.planTiles = null;
          break;
      }
    }
    this.setState({
      tilesVisible,
    });
  };

  handleRecenter = () => {
    if (this.props.userLocation !== undefined) {
      this.leafletMap.setView(
        [this.props.userLocation?.lat, this.props.userLocation?.lng],
        20
      );
    }
  };

  handleRenderUserLocationMarker = () => {
    if (this.props.userLocation !== undefined) {
      if (this.marker) {
        // If the marker already exists, update its position and rotation
        this.marker.setLatLng([
          this.props.userLocation?.lat ?? 0,
          this.props.userLocation?.lng ?? 0,
        ]);
        this.accuracyOverlay.setLatLng([
          this.props.userLocation?.lat ?? 0,
          this.props.userLocation?.lng ?? 0,
        ]);
      } else {
        // If the marker doesn't exist, create a new one
        const markerNode = document.createElement("div");
        const markerRoot = createRoot(markerNode);
        markerRoot.render(
          <div>
            <img src={UserLocationIcon} alt="User Location Icon" />
          </div>
        );

        const markerIcon = L.divIcon({
          className: "origin-center", // Remove the rotate-90 class if it's affecting visibility
          iconSize: [32, 32],
          html: markerNode,
        });

        this.marker = L.marker(
          [
            this.props.userLocation?.lat ?? 0,
            this.props.userLocation?.lng ?? 0,
          ],
          { icon: markerIcon }
        ).addTo(this.leafletMap);
        this.accuracyOverlay = L.circle(
          [
            this.props.userLocation?.lat ?? 0,
            this.props.userLocation?.lng ?? 0,
          ],
          {
            radius: 30, // Use the accuracy value as the radius of the circle.
            fillColor: "#4285F4",
            fillOpacity: 0.3, // Customize the opacity of the circle.
            stroke: false,
          }
        ).addTo(this.leafletMap);
      }
    }
  };

  render() {
    return (
      <div className="relative" style={{ height: 600 }}>
        <div
          className={`flex flex-row-reverse absolute top-2 left-2 z-50 ${
            this.props.screenshotProps?.bounds ? "hidden" : ""
          }`}
        >
          <CustomPopup
            handleTilesToggle={this.handleTilesToggle}
            inspectionId={this.props.inspectionId ?? ""}
            mbtileExistance={this.props.mbtileExistance}
            tilesVisible={this.state.tilesVisible}
          />
        </div>
        <div
          className={`flex flex-col absolute top-2 right-2 z-50 ${
            this.props.screenshotProps?.bounds ? "hidden" : ""
          } lg:flex-row`}
        >
          <IconButton
            icon={
              <div className="flex flex-row items-center justify-center mr-3">
                <ArrowsPointingOutIcon
                  className="block h-5 w-5 text-primary-800"
                  aria-hidden="true"
                />
                <span className="ml-2 text-primary-800 text-sm font-bold">
                  Zoom to defects
                </span>
              </div>
            }
            onClick={this.handleZoomToDefects}
          />
          {this.props.userLocation !== undefined && (
            <IconButton
              className="ml-3 mt-3 lg:mt-0"
              icon={
                <div className="flex flex-row items-center justify-center mr-3">
                  <MapPinIcon
                    className="block h-5 w-5 text-primary-800"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-primary-800 text-sm font-bold">
                    Recenter
                  </span>
                </div>
              }
              onClick={this.handleRecenter}
            />
          )}
        </div>
        <div
          ref={this.map}
          className="bg-gray-100"
          style={{
            height:
              this.props.mapWidth && this.props.mapWidth > 0
                ? this.props.mapWidth
                : 600,
            width:
              this.props.mapWidth && this.props.mapWidth > 0
                ? this.props.mapWidth
                : "100%",
          }}
        />
      </div>
    );
  }
}
