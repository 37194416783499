import axios from "axios";
import React, { Component } from "react";
import Input from "../components/base/Input";
import Footer from "../components/Footer";
import Header, { Pages } from "../components/Header";
import { handleEnquiriesCondition } from "../components/store/actions/enquiresActions";

export interface EnquiriesStateAttribute {
  name: string;
  email: string;
  mobileNo: string;
  message: string;
}

export interface EnquriesStateError {
  nameError: string;
  emailError: string;
  mobileNoError: string;
  messageError: string;
}

interface State {
  enquiriesState: EnquiriesStateAttribute;
  enquiriesStateError: EnquriesStateError;
  loading: boolean;
}

export default class ContactUs extends Component {
  state: State = {
    enquiriesState: {
      name: "",
      email: "",
      message: "",
      mobileNo: "",
    },
    enquiriesStateError: {
      emailError: "",
      messageError: "",
      mobileNoError: "",
      nameError: "",
    },
    loading: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleInputChange = (e: any) => {
    const clonedEnquiriesState = JSON.parse(
      JSON.stringify(this.state.enquiriesState)
    );
    clonedEnquiriesState[e.target.id] = e.target.value;
    this.setState({
      enquiriesState: clonedEnquiriesState,
    });
  };

  handleSubmitEnquiries = () => {
    let conditionsList: string[] = ["name", "mobileNo", "email", "message"];
    const clonedEnquiriesError = JSON.parse(
      JSON.stringify(this.state.enquiriesStateError)
    );
    handleEnquiriesCondition(
      this.state.enquiriesState,
      clonedEnquiriesError,
      conditionsList
    );
    this.setState(
      {
        enquiriesStateError: clonedEnquiriesError,
      },
      async () => {
        if (
          !this.state.enquiriesStateError.nameError &&
          !this.state.enquiriesStateError.mobileNoError &&
          !this.state.enquiriesStateError.emailError &&
          !this.state.enquiriesStateError.messageError
        ) {
          this.setState({
            loading: true,
          });
          try {
            await axios.post(
              "https://asia-southeast2-aero-reality-web.cloudfunctions.net/contactWebhook",
              {
                token: "fErkbNXdJhRrhC8t",
                message: `Email:${this.state.enquiriesState.email}\n\nName:${this.state.enquiriesState.name}\n\nContact:${this.state.enquiriesState.mobileNo}\n\nContent:${this.state.enquiriesState.message}`,
              }
            );
            this.setState({
              enquiriesState: {
                name: "",
                email: "",
                message: "",
                mobileNo: "",
              },
            });
            window.alert(
              "Your message has been submitted, please allow us to revert around 1-3 working days."
            );
          } catch (err) {}
          this.setState({ loading: false });
        }
      }
    );
  };

  render() {
    let defaultClassName =
      "block w-full shadow-sm py-3 px-5 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none";
    if (this.state.enquiriesStateError.messageError !== "") {
      defaultClassName += " mb-0.5 border-red-300";
    }
    return (
      <div>
        {this.state.loading && (
          <div className="fixed z-50 bg-gray-900 min-w-full min-h-screen items-center justify-center flex opacity-70">
            <svg
              className="mr-2 w-8 h-8 text-gray-600 animate-spin fill-sky-600"
              viewBox="0 0 100 101"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.59c0 27.615-22.386 50.001-50 50.001s-50-22.386-50-50 22.386-50 50-50 50 22.386 50 50zm-90.919 0c0 22.6 18.32 40.92 40.919 40.92 22.599 0 40.919-18.32 40.919-40.92 0-22.598-18.32-40.918-40.919-40.918-22.599 0-40.919 18.32-40.919 40.919z"
                className="fill-current"
              />
              <path
                d="M93.968 39.04c2.425-.636 3.894-3.128 3.04-5.486A50 50 0 0041.735 1.279c-2.474.414-3.922 2.919-3.285 5.344.637 2.426 3.12 3.849 5.6 3.484a40.916 40.916 0 0144.131 25.769c.902 2.34 3.361 3.802 5.787 3.165z"
                fill="#0ea5e9"
              />
            </svg>
          </div>
        )}
        <Header page={Pages.CONTACTUS} />
        <div className="relative bg-gray-900 overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-900" />
          </div>
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="bg-gray-900 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                  Contact Us
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-200">
                  For general business and enquiries, please contact us via
                  contact below or submit an enquiries in the form
                </p>
                <dl className="mt-8 text-base text-gray-200">
                  <div>
                    <dt className="sr-only">Postal address</dt>
                    <dd>
                      <p>IOI Rio Office Tower</p>
                      <p>Bandar Puteri Puchong, 47100</p>
                      <p>Puchong, Selangor </p>
                    </dd>
                  </div>
                  {/* <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">+6012-3456789</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">support@airlytic.co</span>
                    </dd>
                  </div> */}
                </dl>
              </div>
            </div>
            <div className="bg-gray-900 py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <div className="grid grid-cols-1 gap-y-6">
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      Full name
                    </label>
                    <Input
                      id="name"
                      className="mt-1"
                      placeholder="Name"
                      value={this.state.enquiriesState.name}
                      error={this.state.enquiriesStateError.nameError}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <Input
                      id="email"
                      className="mt-1"
                      placeholder="Email"
                      value={this.state.enquiriesState.email}
                      error={this.state.enquiriesStateError.emailError}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <Input
                      id="mobileNo"
                      className="mt-1"
                      placeholder="Mobile Number"
                      value={this.state.enquiriesState.mobileNo}
                      error={this.state.enquiriesStateError.mobileNoError}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      className={defaultClassName}
                      placeholder="Message"
                      value={this.state.enquiriesState.message}
                      onChange={this.handleInputChange}
                    />
                    <span className="text-red-500 text-sm">
                      {this.state.enquiriesStateError.messageError}
                    </span>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-3 px-6 border border-white shadow-sm text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      onClick={this.handleSubmitEnquiries}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
