import { Menu, Transition } from "@headlessui/react";
import { MapIcon } from "@heroicons/react/24/outline";
import { PopUp, Toggle } from "blixify-ui-web/lib";
import React, { Component } from "react";

export interface TilesVisible {
  rgb: boolean;
  plan: boolean;
  thermal: boolean;
}

interface Props {
  inspectionId: string;
  mbtileExistance: string[];
  tilesVisible: TilesVisible;
  handleTilesToggle: (type: string) => void;
}

export default class CustomPopup extends Component<Props> {
  renderTilesToggle = () => {
    const toggles = JSON.parse(
      JSON.stringify({
        rgb: "RGB",
        thermal: "Thermal",
        plan: "PV Layout",
      })
    );
    const toggleList: JSX.Element[] = [];
    this.props.mbtileExistance.map((eachMbTiles, index) => {
      const tilesVisible = JSON.parse(JSON.stringify(this.props.tilesVisible));
      toggleList.push(
        <div className="flex flex-row mt-2" key={index}>
          <Toggle
            direction="right"
            text={toggles[eachMbTiles]}
            value={tilesVisible[eachMbTiles]}
            onClick={this.props.handleTilesToggle.bind(this, eachMbTiles)}
          />
        </div>
      );
      return null;
    });

    return (
      <div className="w-48 p-2 border-solid border-2 border-primary-700 rounded-md flex flex-col">
        {toggleList}
      </div>
    );
  };

  render() {
    return (
      <PopUp
        anchor="left"
        ctaComp={
          <div className="flex flex-row items-center justify-center mr-3 px-2 py-1">
            <MapIcon
              className="block h-8 w-8 text-primary-800"
              aria-hidden="true"
            />
            <span className="ml-2 text-primary-800 text-md font-bold">Map</span>
          </div>
        }
        renderComp={this.renderTilesToggle()}
        lib={{
          Menu,
          Transition,
        }}
      />
    );
  }
}
