import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Component, Fragment, ReactElement } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import LogoTrans from "../assets/logo_trans.png";

interface Props {
  page?: Pages;
}

export enum Pages {
  HOME = "Home",
  SOLUTIONS = "Solutions",
  SERVICES = "Services",
  CONTACTUS = "Contact Us",
}

const navigation = [
  { name: "Home", href: "/" },
  { name: "Services", href: "/services" },
  { name: "Solutions", href: "/solutions" },
  { name: "Contact Us", href: "/contact" },
];

export default class Header extends Component<Props> {
  renderHeaderItems = () => {
    const items: ReactElement[] = [];

    navigation.map((eachNavigation) => {
      let defaultClass =
        "text-base font-medium text-white hover:text-gray-300 cursor-pointer";

      if (this.props.page === eachNavigation.name) {
        defaultClass =
          "border-b text-white text-base font-medium cursor-pointer";
      }

      items.push(
        <Link to={eachNavigation.href} key={eachNavigation.name}>
          <p className={defaultClass} aria-current="page">
            {eachNavigation.name}
          </p>
        </Link>
      );
      return "";
    });

    return items;
  };

  render() {
    return (
      <>
        <Popover as="header" className="relative">
          <div className="bg-gray-900 pt-6">
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <img className="h-14 w-auto" src={Logo} alt="" />
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-8 md:flex md:ml-10">
                  {this.renderHeaderItems()}
                </div>
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
            >
              <div className="rounded-lg shadow-md bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img className="h-14 w-auto" src={LogoTrans} alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-gray-700 rounded-md p-2 inline-flex items-center justify-center text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        key={item.name}
                        className="block px-3 py-2 rounded-md text-base font-medium text-cyan-500 hover:bg-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </>
    );
  }
}
